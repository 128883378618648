import axios from 'axios'
import { getCustomerId } from '../../utils/helpers'

const BASE_URL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PRACTITIONER_API_DOMAIN}/${process.env.REACT_APP_SECURE_DC_API_PATH}/`
const API_VERSION = 'v2'

export async function getUsers(userToken, instanceId) {
  const config = {
    headers: {
      Authorization: userToken,
      version: API_VERSION,
      customer_id: getCustomerId(),
    },
  }
  try {
    const url = new URL(`${BASE_URL}users`)
    url.search = new URLSearchParams({
      instance_id: instanceId,
    }).toString()

    const res = await axios.get(url.toString(), config)
    console.debug(res)
    return res
  } catch (error) {
    return error
  }
}

export async function getUserMappings() {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PRACTITIONER_API_DOMAIN}/public/doc-cirrus/professionals`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  } catch (error) {
    return error
  }
}

export async function getPractitionerMappings(userToken) {
  const config = {
    headers: {
      Authorization: userToken,
      version: API_VERSION,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/mappings`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function getUserMappingById(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/${id}/mappings`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function getUserById(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/${id}`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function postUserMappingById(userToken, id, mappingDataInfo) {
  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.post(`${BASE_URL}users/${id}/mappings`, mappingDataInfo, config)
    return res
  } catch (error) {
    return error
  }
}
