import React, { useReducer } from 'react'
import { getCachedUserData, updateForm } from '../../api/pracuseravailability/pracUserAvailability'
import PracUserAvailabilityReducer from './PracUserAvailabilityReducer'
import PracUserAvailabilityContext from './PracUserAvailabilityContext'
import {
  CLEAR_ERRORS,
  CLEAR_OBJECTS,
  CREATE_FORM_DATA_FAIL,
  CREATE_FORM_DATA_SUCCESS,
  GET_ALL_CACHED_USER_DATA_FAIL,
  GET_ALL_CACHED_USER_DATA_SUCCESS,
  SET_TO_DEFAULT,
} from '../../store/actions'

const PracUserAvailabilityState = (props) => {
  const initialState = {
    cachedUserData: [],
    error: null,
    successMessage: null,
    formSuccessMessage: null,
  }
  const [state, dispatch] = useReducer(PracUserAvailabilityReducer, initialState)

  const getAllCachedUserData = async (userToken) => {
    try {
      const response = await getCachedUserData(userToken)
      console.debug(response)
      dispatch({
        type: GET_ALL_CACHED_USER_DATA_SUCCESS,
        payload: response?.data?.data?.linked_users,
        successMessage:
          response?.data?.data?.linked_users?.length !== 0
            ? 'Record Found!!'
            : 'Record Not Found!!',
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_CACHED_USER_DATA_FAIL,
        payload: error.message,
      })
    }
  }

  const createFormData = async (userToken, formData) => {
    try {
      const response = await updateForm(userToken, formData)
      console.debug(response)
      dispatch({
        type: CREATE_FORM_DATA_SUCCESS,
        payload: response?.data,
        formSuccessMessage: 'Form created successfully',
      })
    } catch (error) {
      dispatch({
        type: CREATE_FORM_DATA_FAIL,
        payload: error.message,
      })
    }
  }

  const clearRecords = () => {
    dispatch({ type: CLEAR_OBJECTS })
  }

  const clearError = () => {
    dispatch({ type: CLEAR_ERRORS })
  }

  const setToDefault = () => {
    dispatch({ type: SET_TO_DEFAULT })
  }

  return (
    <PracUserAvailabilityContext.Provider
      value={{
        cachedUserData: state.cachedUserData,
        error: state.error,
        successMessage: state.successMessage,
        formSuccessMessage: state.formSuccessMessage,
        getAllCachedUserData,
        createFormData,
        clearError,
        setToDefault,
        clearRecords,
      }}
    >
      {props.children}
    </PracUserAvailabilityContext.Provider>
  )
}
export default PracUserAvailabilityState
