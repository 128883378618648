import React, { useReducer, useContext } from 'react'
import {
  getPatients,
  getUserById,
  updateUserById,
  getUserAccountById,
  getAppointmentByUser,
  updateUserAccountById,
  getUserAccountHealthInsuranceById,
  getUserAccountAddressesById,
  updateUserAccountAddressesById,
  updateUserAccountHealthInsuranceById,
  syncPatients,
  postUserMappingById,
} from '../../api/patient/PatientCalls'
import PatientReducer from './PatientReducer'
import PatientContext from './PatientContext'
import {
  GET_ALL_PATIENT_SUCCESS,
  GET_ALL_PATIENT_FAIL,
  GET_PATIENT_ACCOUNT_DETAILS_SUCCESS,
  GET_PATIENT_ACCOUNT_DETAILS_FAIL,
  GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS,
  GET_PATIENT_APPOINTMENT_DETAILS_FAIL,
  UPDATE_PATIENT_ACCOUNT_INFORMATION_SUCCESS,
  UPDATE_PATIENT_ACCOUNT_INFORMATION_FAIL,
  GET_PATIENT_HEALTH_PROFILE_DETAILS_SUCCESS,
  GET_PATIENT_HEALTH_PROFILE_DETAILS_FAIL,
  GET_PATIENT_BY_ID_SUCCESS,
  GET_PATIENT_BY_ID_FAIL,
  GET_PATIENT_ADDRESS_DETAILS_SUCCESS,
  GET_PATIENT_ADDRESS_DETAILS_FAIL,
  UPDATE_PATIENT_BASIC_INFO_SUCCESS,
  UPDATE_PATIENT_BASIC_INFO_FAIL,
  UPDATE_PATIENT_ADDRESS_INFO_SUCCESS,
  UPDATE_PATIENT_ADDRESS_INFO_FAIL,
  UPDATE_PATIENT_HEALTH_INFO_SUCCESS,
  UPDATE_PATIENT_HEALTH_INFO_FAIL,
  SYNC_PATIENT_DATA_SUCCESS,
  SYNC_PATIENT_DATA_FAIL,
  CLEAR_OBJECTS,
  CLEAR_ERRORS,
  SET_TO_DEFAULT,
} from '../../store/actions'

const PatientState = (props) => {
  const initialState = {
    patients: [],
    singlePatientRecord: null,
    patientAccountDetails: null,
    patientAppointmentDetails: null,
    patientHealthInsuranceDetails: null,
    patientAddressDetails: null,
    successMessage: null,
    error: null,
  }
  const [state, dispatch] = useReducer(PatientReducer, initialState)

  // getPatientsCall
  const getAllPatients = async (userToken) => {
    try {
      var response = await getPatients(userToken)
      dispatch({
        type: GET_ALL_PATIENT_SUCCESS,
        payload: response.data.data,
        successMessage: response.data.data.length !== 0 ? 'Record Found!' : 'Record Not Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_PATIENT_FAIL,
        payload: response.message,
      })
    }
  }

  // getSinglePatientRecord
  const getSinglePatientRecord = async (userToken, id) => {
    console.debug('in getSinglePatientRecord')
    console.debug('id =>  ' + id)
    try {
      var response = await getUserById(userToken, id)
      dispatch({
        type: GET_PATIENT_BY_ID_SUCCESS,
        payload: response.data,
        successMessage: 'Record Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_PATIENT_BY_ID_FAIL,
        payload: response.message,
      })
    }
  }

  // updatePatientBasicInfo
  const updatePatientBasicInfo = async (userToken, id, patientBasicInfo) => {
    try {
      var response = await updateUserById(userToken, id, patientBasicInfo)
      dispatch({
        type: UPDATE_PATIENT_BASIC_INFO_SUCCESS,
        payload: response.data.data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_PATIENT_BASIC_INFO_FAIL,
        payload: response.message,
      })
    }
  }

  // getAccountDetailsCall
  const getAccountDetails = async (userToken, id) => {
    try {
      var response = await getUserAccountById(userToken, id)
      dispatch({
        type: GET_PATIENT_ACCOUNT_DETAILS_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: GET_PATIENT_ACCOUNT_DETAILS_FAIL,
        payload: response.message,
      })
    }
  }

  // updatePatientAccountInfoCall
  const updatePatientAccountInfo = async (userToken, id, patientAccountInfo) => {
    try {
      var response = await updateUserAccountById(userToken, id, patientAccountInfo)
      dispatch({
        type: UPDATE_PATIENT_ACCOUNT_INFORMATION_SUCCESS,
        payload: response.data.data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_PATIENT_ACCOUNT_INFORMATION_FAIL,
        payload: response.message,
      })
    }
  }

  // getPatientAddressById
  const getPatientAddressById = async (userToken, id) => {
    try {
      var response = await getUserAccountAddressesById(userToken, id)
      dispatch({
        type: GET_PATIENT_ADDRESS_DETAILS_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: GET_PATIENT_ADDRESS_DETAILS_FAIL,
        payload: response.message,
      })
    }
  }

  // updateUserAccountAddressesById
  const updatePatientAddressesById = async (userToken, id, patientAddressInfo) => {
    try {
      var response = await updateUserAccountAddressesById(userToken, id, patientAddressInfo)
      dispatch({
        type: UPDATE_PATIENT_ADDRESS_INFO_SUCCESS,
        payload: response.data.data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_PATIENT_ADDRESS_INFO_FAIL,
        payload: response.message,
      })
    }
  }

  // getPatientHealthProfileById
  const getPatientHealthInsuranceById = async (userToken, id) => {
    try {
      var response = await getUserAccountHealthInsuranceById(userToken, id)
      dispatch({
        type: GET_PATIENT_HEALTH_PROFILE_DETAILS_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: GET_PATIENT_HEALTH_PROFILE_DETAILS_FAIL,
        payload: response.message,
      })
    }
  }

  // updatePatientHealthInsuranceInfo
  const updatePatientHealthInsuranceInfo = async (userToken, id, patientHealthInsuranceInfo) => {
    try {
      var response = await updateUserAccountHealthInsuranceById(
        userToken,
        id,
        patientHealthInsuranceInfo,
      )

      dispatch({
        type: UPDATE_PATIENT_HEALTH_INFO_SUCCESS,
        payload: response.data.data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_PATIENT_HEALTH_INFO_FAIL,
        payload: response.message,
      })
    }
  }

  // syncPatientData
  const syncPatientData = async (userToken, id) => {
    try {
      var response = await syncPatients(userToken, id)
      dispatch({
        type: SYNC_PATIENT_DATA_SUCCESS,
        payload: response.data.data,
      })
    } catch (error) {
      dispatch({
        type: SYNC_PATIENT_DATA_FAIL,
        payload: response.message,
      })
    }
  }

  const clearRecords = () => {
    dispatch({ type: CLEAR_OBJECTS })
  }

  const clearError = () => {
    dispatch({ type: CLEAR_ERRORS })
  }

  const setToDefault = () => {
    dispatch({ type: SET_TO_DEFAULT })
  }
  return (
    <PatientContext.Provider
      value={{
        patients: state.patients,
        singlePatientRecord: state.singlePatientRecord,
        patientAccountDetails: state.patientAccountDetails,
        patientAppointmentDetails: state.patientAppointmentDetails,
        patientHealthInsuranceDetails: state.patientHealthInsuranceDetails,
        patientAddressDetails: state.patientAddressDetails,
        successMessage: state.successMessage,
        error: state.error,
        getAllPatients,
        getSinglePatientRecord,
        getAccountDetails,
        updatePatientAccountInfo,
        getPatientHealthInsuranceById,
        clearRecords,
        getPatientAddressById,
        updatePatientBasicInfo,
        updatePatientAddressesById,
        updatePatientHealthInsuranceInfo,
        syncPatientData,
        clearError,
        setToDefault,
      }}
    >
      {props.children}
    </PatientContext.Provider>
  )
}
export default PatientState
