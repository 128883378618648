import React, { useReducer } from 'react'
import { getCalendars } from '../../api/dccalendar/DcCalendarCalls'
import DcCalendarReducer from './DcCalendarReducer'
import DcCalendarContext from './DcCalendarContext'
import {
  CLEAR_ERRORS,
  GET_ALL_DC_CALENDAR_FAIL,
  GET_ALL_DC_CALENDAR_SUCCESS,
  SET_TO_DEFAULT,
} from '../../store/actions'

const DcCalendarState = (props) => {
  const initialState = {
    dcCalendars: [],
    singleCalendarRecord: null,
    error: null,
    successMessage: null,
  }
  const [state, dispatch] = useReducer(DcCalendarReducer, initialState)

  //getAllDcCalendars
  const getAllDcCalendars = async (userToken) => {
    try {
      var response = await getCalendars(userToken)
      dispatch({
        type: GET_ALL_DC_CALENDAR_SUCCESS,
        payload: response.data.data,
        successMessage: 'Record Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_DC_CALENDAR_FAIL,
        payload: response.message,
      })
    }
  }

  const clearError = () => {
    dispatch({ type: CLEAR_ERRORS })
  }

  const setToDefault = () => {
    dispatch({ type: SET_TO_DEFAULT })
  }

  return (
    <DcCalendarContext.Provider
      value={{
        dcCalendars: state.dcCalendars,
        singleCalendarRecord: state.singleCalendarRecord,
        error: state.error,
        successMessage: state.successMessage,
        getAllDcCalendars,
        clearError,
        setToDefault,
      }}
    >
      {props.children}
    </DcCalendarContext.Provider>
  )
}
export default DcCalendarState
