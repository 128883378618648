import React, { useReducer } from 'react'
import { getById } from '../../api/patuserhealthprofile/PatUserHealthProfileCall'
import PatUserHealthProfileReducer from './PatUserHealthProfileReducer'
import PatUserHealthProfileContext from './PatUserHealthProfileContext'
import {
  GET_PAT_USER_BY_ID_HEALTH_PROFILE_SUCCESS,
  GET_PAT_USER_BY_ID_HEALTH_PROFILE_FAIL,
  CLEAR_OBJECTS,
  CLEAR_ERRORS,
  SET_TO_DEFAULT,
} from '../../store/actions'
const PatUserHealthProfileState = (props) => {
  const initialState = {
    singleUserHealthProfile: null,
    error: null,
    successMessage: null,
  }
  const [state, dispatch] = useReducer(PatUserHealthProfileReducer, initialState)
  // getDcPatientById
  const getPatientUserHealthProfileById = async (userToken, id) => {
    console.debug('cheking api')
    try {
      var response = await getById(userToken, id)
      console.debug(response)
      dispatch({
        type: GET_PAT_USER_BY_ID_HEALTH_PROFILE_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: GET_PAT_USER_BY_ID_HEALTH_PROFILE_FAIL,
        payload: response.message,
      })
    }
  }

  const clearSinglePatientProfileRecord = () => {
    dispatch({ type: CLEAR_OBJECTS })
  }

  const clearError = () => {
    dispatch({ type: CLEAR_ERRORS })
  }

  const setToDefault = () => {
    dispatch({ type: SET_TO_DEFAULT })
  }

  return (
    <PatUserHealthProfileContext.Provider
      value={{
        singleUserHealthProfile: state.singleUserHealthProfile,
        successMessage: state.successMessage,
        error: state.error,
        getPatientUserHealthProfileById,
        clearSinglePatientProfileRecord,
        clearError,
        setToDefault,
      }}
    >
      {props.children}
    </PatUserHealthProfileContext.Provider>
  )
}
export default PatUserHealthProfileState
