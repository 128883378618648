import axios from 'axios'
import { getCustomerId } from '../../utils/helpers'

const BASE_URL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/`
const BASE_URL_ADMIN = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_API_PATH}/`

export async function getPatients(userToken) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/cached`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function getUserById(userToken, id) {
  console.debug('in getUserById')
  console.debug('id => ' + id)

  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/${id}`, config)
    console.debug('res in patient call')
    console.debug(res)
    return res
  } catch (error) {
    console.debug('error in getUserByID')
    console.debug(error)
    return error
  }
}

export async function updateUserById(userToken, id, patientBasicInfo) {
  console.debug(patientBasicInfo)

  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.post(`${BASE_URL}users/${id}`, patientBasicInfo, config)
    console.debug(res)
    return res
  } catch (error) {
    console.debug(error)
    return error
  }
}

export async function getUserAccountById(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}user/accounts/${id}`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function getAppointmentByUser(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}user/appointments/?user_id=${id}`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function updateUserAccountById(userToken, id, patientAccountInfo) {
  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.post(`${BASE_URL}user/accounts/${id}`, patientAccountInfo, config)
    return res
  } catch (error) {
    return error
  }
}

export async function updateUserAccountHealthInsuranceById(
  userToken,
  id,
  patientHealthInsuranceInfo,
) {
  console.debug(patientHealthInsuranceInfo)
  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
    },
  }
  try {
    const res = await axios.post(
      `${BASE_URL}user/accounts/${id}/health-insurances`,
      patientHealthInsuranceInfo,
      config,
    )
    console.debug(res)
    return res
  } catch (error) {
    console.debug(error)
    return error
  }
}

export async function getUserAccountHealthInsuranceById(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}user/accounts/${id}/health-insurances`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function updateUserAccountAddressesById(userToken, id, patientAddressInfo) {
  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.post(
      `${BASE_URL}user/accounts/${id}/addresses`,
      patientAddressInfo,
      config,
    )
    return res
  } catch (error) {
    return error
  }
}

export async function getUserAccountAddressesById(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}user/accounts/${id}/addresses`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function syncPatients(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'text/plain',
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.post(`${BASE_URL_ADMIN}patients/${id}/sync-data`, config)
    return res
  } catch (error) {
    return error
  }
}
