import React, { useReducer, useContext } from 'react'
import {
  getPatients,
  getUserMappingById,
  getPatientById,
  postUserMappingById,
  getUserMappings,
} from '../../api/dcpatient/DcPatientCalls'
import DcPatientReducer from './DcPatientReducer'
import DcPatientContext from './DcPatientContext'
import {
  GET_ALL_DC_PATIENTS_SUCCESS,
  GET_ALL_DC_PATIENTS_FAIL,
  GET_DC_PATIENTS_IDS_SUCCESS,
  GET_DC_PATIENTS_IDS_FAIL,
  GET_DC_PATIENT_BY_ID_SUCCESS,
  GET_DC_PATIENT_BY_ID_FAIL,
  POST_PATIENT_DATA_SUCCESS,
  POST_PATIENT_DATA_FAIL,
  GET_DC_PATIENT_MAPPINGS_SUCCESS,
  GET_DC_PATIENT_MAPPINGS_FAIL,
  CLEAR_OBJECTS,
  CLEAR_ERRORS,
  SET_TO_DEFAULT,
} from '../../store/actions'
const DcPatientState = (props) => {
  const initialState = {
    dcPatients: [],
    dcPatientsIds: null,
    dcPatientMappings: [],
    singleDcPatientRecord: null,
    error: null,
    successMessage: null,
  }
  const [state, dispatch] = useReducer(DcPatientReducer, initialState)
  // getPatientsCall
  const getDcPatients = async (userToken) => {
    try {
      var response = await getPatients(userToken)
      dispatch({
        type: GET_ALL_DC_PATIENTS_SUCCESS,
        payload: response.data.data,
        successMessage: 'Record Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_DC_PATIENTS_FAIL,
        payload: response.message,
      })
    }
  }

  // getDcPatientMappings
  const getDcPatientMappings = async (userToken) => {
    try {
      var response = await getUserMappings(userToken)
      dispatch({
        type: GET_DC_PATIENT_MAPPINGS_SUCCESS,
        payload: response.data.data,
      })
    } catch (error) {
      dispatch({
        type: GET_DC_PATIENT_MAPPINGS_FAIL,
        payload: response.message,
      })
    }
  }

  // getDcPatientById
  const getDcPatientById = async (userToken, id, instanceId) => {
    try {
      var response = await getPatientById(userToken, id, instanceId)
      console.debug(response)
      dispatch({
        type: GET_DC_PATIENT_BY_ID_SUCCESS,
        payload: response.data,
        successMessage: 'Record Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_DC_PATIENT_BY_ID_FAIL,
        payload: response.message,
      })
    }
  }

  // getPatientsCall
  const getDcPatientMappingByIds = async (userToken, id) => {
    try {
      var response = await getUserMappingById(userToken, id)
      dispatch({
        type: GET_DC_PATIENTS_IDS_SUCCESS,
        payload: response.data,
        successMessage: 'Record Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_DC_PATIENTS_IDS_FAIL,
        payload: response.message,
      })
    }
  }

  // sendPatientMappingData
  const sendPatientMappingData = async (userToken, id, mappingDataInfo) => {
    try {
      var response = await postUserMappingById(userToken, id, mappingDataInfo)
      dispatch({
        type: POST_PATIENT_DATA_SUCCESS,
        payload: response.data.data,
      })
    } catch (error) {
      dispatch({
        type: POST_PATIENT_DATA_FAIL,
        payload: response.message,
      })
    }
  }

  const clearRecords = () => {
    dispatch({ type: CLEAR_OBJECTS })
  }

  const clearError = () => {
    dispatch({ type: CLEAR_ERRORS })
  }

  const setToDefault = () => {
    dispatch({ type: SET_TO_DEFAULT })
  }

  return (
    <DcPatientContext.Provider
      value={{
        dcPatients: state.dcPatients,
        dcPatientsIds: state.dcPatientsIds,
        dcPatientMappings: state.dcPatientMappings,
        singleDcPatientRecord: state.singleDcPatientRecord,
        successMessage: state.successMessage,
        error: state.error,
        getDcPatients,
        getDcPatientMappingByIds,
        getDcPatientById,
        sendPatientMappingData,
        getDcPatientMappings,
        clearRecords,
        clearError,
        setToDefault,
      }}
    >
      {props.children}
    </DcPatientContext.Provider>
  )
}
export default DcPatientState
