import { darken, getLuminance, lighten } from '@mui/material'
import { rgbToHex } from '@coreui/utils'

export const hexToRgb = (hex) => {
  let r = 0,
    g = 0,
    b = 0

  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }

  return [r, g, b]
}
const isDark = (color) => getLuminance(color) < 0.5
const darkenColor = (color, coefficient) => rgbToHex(darken(color, coefficient))
export const lightenColor = (color, coefficient) => rgbToHex(lighten(color, coefficient))
export const getTextColor = (color) => (isDark(color) ? '#FFFFFF' : '#000000')
export const getHoverColors = (color) => {
  const hoverBgColor = isDark(color) ? lightenColor(color, 0.15) : darkenColor(color, 0.15)

  return {
    hoverBgColor,
    hoverTextColor: getTextColor(hoverBgColor),
  }
}
