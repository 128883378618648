import React, { useEffect } from 'react'
import PatientState from './context/patient/PatientState'
import ProfessionalState from './context/professional/ProfessionalState'
import AppointmentState from './context/appointment/AppointmentState'
import DcProfessionalState from './context/dcprofessional/DcProfessionalState'
import DcPatientState from './context/dcpatient/DcPatientState'
import DcCalendarState from './context/dccalendar/DcCalendarState'
import PatUserHealthProfileState from './context/patuserhealthprofile/PatUserHealthProfileState'
import PracUserAvailabilityState from './context/pracuseravailability/PracUserAvailabilityState'
import AlertState from './context/alert/AlertState'
import './scss/style.scss'
import Navigation from './Navigation'
import './i18n'
import 'moment/locale/de'
import useLocations from './hooks/useLocations'
import usePrismic from './hooks/usePrismic'
import { Toaster } from 'react-hot-toast'
import useRequiredAuthCheck from './hooks/useRequiredAuthCheck'

const App = () => {
  usePrismic()
  useLocations()
  useRequiredAuthCheck()

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      console.debug = function () {}
    }
  }, [])

  return (
    <AlertState>
      <PatientState>
        <ProfessionalState>
          <AppointmentState>
            <DcProfessionalState>
              <DcPatientState>
                <DcCalendarState>
                  <PatUserHealthProfileState>
                    <PracUserAvailabilityState>
                      <Navigation />
                      <Toaster />
                    </PracUserAvailabilityState>
                  </PatUserHealthProfileState>
                </DcCalendarState>
              </DcPatientState>
            </DcProfessionalState>
          </AppointmentState>
        </ProfessionalState>
      </PatientState>
    </AlertState>
  )
}

export default App
