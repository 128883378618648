export const spinnerConfig = {
  settings: {
    scale: 0.5,
    duration: 1300,
    amount: 72,
    delay: 9,
    dotSize: 7,
    dynamicDotSize: false,
  },
  positions: [
    {
      x: 107.66799926757812,
      y: 26.85300064086914,
    },
    {
      x: 107.98655700683594,
      y: 26.55063819885254,
    },
    {
      x: 108.30585479736328,
      y: 26.249053955078125,
    },
    {
      x: 108.62588500976562,
      y: 25.94825553894043,
    },
    {
      x: 108.9466781616211,
      y: 25.648258209228516,
    },
    {
      x: 109.26822662353516,
      y: 25.349079132080078,
    },
    {
      x: 109.59061431884766,
      y: 25.05080223083496,
    },
    {
      x: 109.91382598876953,
      y: 24.753421783447266,
    },
    {
      x: 110.23783874511719,
      y: 24.456907272338867,
    },
    {
      x: 110.5626449584961,
      y: 24.161270141601562,
    },
    {
      x: 110.88826751708984,
      y: 23.866531372070312,
    },
    {
      x: 111.21484375,
      y: 23.57284164428711,
    },
    {
      x: 111.54224395751953,
      y: 23.280080795288086,
    },
    {
      x: 111.87049102783203,
      y: 22.988264083862305,
    },
    {
      x: 112.19960021972656,
      y: 22.697418212890625,
    },
    {
      x: 112.52970123291016,
      y: 22.407705307006836,
    },
    {
      x: 112.86068725585938,
      y: 22.118995666503906,
    },
    {
      x: 113.19255065917969,
      y: 21.831302642822266,
    },
    {
      x: 113.525390625,
      y: 21.54473876953125,
    },
    {
      x: 113.85922241210938,
      y: 21.259323120117188,
    },
    {
      x: 114.19396209716797,
      y: 20.974979400634766,
    },
    {
      x: 114.52967071533203,
      y: 20.69178581237793,
    },
    {
      x: 114.86646270751953,
      y: 20.409868240356445,
    },
    {
      x: 115.20418548583984,
      y: 20.129079818725586,
    },
    {
      x: 115.54293060302734,
      y: 19.849519729614258,
    },
    {
      x: 115.8827896118164,
      y: 19.57131004333496,
    },
    {
      x: 116.22361755371094,
      y: 19.294294357299805,
    },
    {
      x: 116.5655517578125,
      y: 19.01864242553711,
    },
    {
      x: 116.90858459472656,
      y: 18.744361877441406,
    },
    {
      x: 117.25262451171875,
      y: 18.471343994140625,
    },
    {
      x: 117.597900390625,
      y: 18.199892044067383,
    },
    {
      x: 117.94422912597656,
      y: 17.92978286743164,
    },
    {
      x: 118.29170989990234,
      y: 17.66115951538086,
    },
    {
      x: 118.64037322998047,
      y: 17.39406967163086,
    },
    {
      x: 118.9901351928711,
      y: 17.128421783447266,
    },
    {
      x: 119.3411865234375,
      y: 16.86448097229004,
    },
    {
      x: 119.69332122802734,
      y: 16.60198974609375,
    },
    {
      x: 120.04680633544922,
      y: 16.341310501098633,
    },
    {
      x: 120.40140533447266,
      y: 16.082157135009766,
    },
    {
      x: 120.75735473632812,
      y: 15.824854850769043,
    },
    {
      x: 121.11446380615234,
      y: 15.569177627563477,
    },
    {
      x: 121.47294616699219,
      y: 15.315424919128418,
    },
    {
      x: 121.83262634277344,
      y: 15.063366889953613,
    },
    {
      x: 122.1937255859375,
      y: 14.813343048095703,
    },
    {
      x: 122.55602264404297,
      y: 14.5650634765625,
    },
    {
      x: 122.91978454589844,
      y: 14.318940162658691,
    },
    {
      x: 123.28480529785156,
      y: 14.074679374694824,
    },
    {
      x: 123.6512451171875,
      y: 13.832557678222656,
    },
    {
      x: 124.01904296875,
      y: 13.592501640319824,
    },
    {
      x: 124.38819885253906,
      y: 13.354546546936035,
    },
    {
      x: 124.75883483886719,
      y: 13.118901252746582,
    },
    {
      x: 125.13082122802734,
      y: 12.885376930236816,
    },
    {
      x: 125.50428771972656,
      y: 12.654243469238281,
    },
    {
      x: 125.87918853759766,
      y: 12.425451278686523,
    },
    {
      x: 126.2554931640625,
      y: 12.198954582214355,
    },
    {
      x: 126.63334655761719,
      y: 11.975055694580078,
    },
    {
      x: 127.01264190673828,
      y: 11.753625869750977,
    },
    {
      x: 127.39337921142578,
      y: 11.534668922424316,
    },
    {
      x: 127.77567291259766,
      y: 11.318442344665527,
    },
    {
      x: 128.1594696044922,
      y: 11.10489559173584,
    },
    {
      x: 128.54473876953125,
      y: 10.89401626586914,
    },
    {
      x: 128.93148803710938,
      y: 10.685887336730957,
    },
    {
      x: 129.31985473632812,
      y: 10.480757713317871,
    },
    {
      x: 129.709716796875,
      y: 10.278494834899902,
    },
    {
      x: 130.10107421875,
      y: 10.079143524169922,
    },
    {
      x: 130.4939422607422,
      y: 9.882783889770508,
    },
    {
      x: 130.88839721679688,
      y: 9.6896390914917,
    },
    {
      x: 131.2843780517578,
      y: 9.499652862548828,
    },
    {
      x: 131.681884765625,
      y: 9.312847137451172,
    },
    {
      x: 132.0808868408203,
      y: 9.129288673400879,
    },
    {
      x: 132.4813995361328,
      y: 8.949050903320312,
    },
    {
      x: 132.88343811035156,
      y: 8.772196769714355,
    },
    {
      x: 133.28701782226562,
      y: 8.598933219909668,
    },
    {
      x: 133.69207763671875,
      y: 8.429169654846191,
    },
    {
      x: 134.0986328125,
      y: 8.262975692749023,
    },
    {
      x: 134.5066375732422,
      y: 8.100418090820312,
    },
    {
      x: 134.91610717773438,
      y: 7.941551208496094,
    },
    {
      x: 135.32701110839844,
      y: 7.7864460945129395,
    },
    {
      x: 135.7393341064453,
      y: 7.635153293609619,
    },
    {
      x: 136.15306091308594,
      y: 7.487730503082275,
    },
    {
      x: 136.5681610107422,
      y: 7.344239711761475,
    },
    {
      x: 136.984619140625,
      y: 7.204725742340088,
    },
    {
      x: 137.40240478515625,
      y: 7.069240093231201,
    },
    {
      x: 137.8214874267578,
      y: 6.937838077545166,
    },
    {
      x: 138.24185180664062,
      y: 6.810560703277588,
    },
    {
      x: 138.6634521484375,
      y: 6.687453746795654,
    },
    {
      x: 139.0862579345703,
      y: 6.568554401397705,
    },
    {
      x: 139.51022338867188,
      y: 6.453901290893555,
    },
    {
      x: 139.93533325195312,
      y: 6.343526840209961,
    },
    {
      x: 140.36154174804688,
      y: 6.237468242645264,
    },
    {
      x: 140.78880310058594,
      y: 6.135747909545898,
    },
    {
      x: 141.2170867919922,
      y: 6.038386344909668,
    },
    {
      x: 141.64633178710938,
      y: 5.945408821105957,
    },
    {
      x: 142.07650756835938,
      y: 5.856828212738037,
    },
    {
      x: 142.507568359375,
      y: 5.772657871246338,
    },
    {
      x: 142.93948364257812,
      y: 5.692903518676758,
    },
    {
      x: 143.37216186523438,
      y: 5.6175713539123535,
    },
    {
      x: 143.8056182861328,
      y: 5.546661853790283,
    },
    {
      x: 144.23976135253906,
      y: 5.480172634124756,
    },
    {
      x: 144.67454528808594,
      y: 5.418095111846924,
    },
    {
      x: 145.10995483398438,
      y: 5.360415458679199,
    },
    {
      x: 145.54592895507812,
      y: 5.307194232940674,
    },
    {
      x: 145.98240661621094,
      y: 5.258362293243408,
    },
    {
      x: 146.4193572998047,
      y: 5.213870525360107,
    },
    {
      x: 146.85670471191406,
      y: 5.173695087432861,
    },
    {
      x: 147.29444885253906,
      y: 5.137802600860596,
    },
    {
      x: 147.73251342773438,
      y: 5.106159687042236,
    },
    {
      x: 148.1708526611328,
      y: 5.078726291656494,
    },
    {
      x: 148.60943603515625,
      y: 5.0554633140563965,
    },
    {
      x: 149.04823303222656,
      y: 5.036324501037598,
    },
    {
      x: 149.48716735839844,
      y: 5.021263122558594,
    },
    {
      x: 149.92623901367188,
      y: 5.010298728942871,
    },
    {
      x: 150.36538696289062,
      y: 5.003321170806885,
    },
    {
      x: 150.80458068847656,
      y: 5.000256061553955,
    },
    {
      x: 151.2437744140625,
      y: 5.001044750213623,
    },
    {
      x: 151.68296813964844,
      y: 5.005626678466797,
    },
    {
      x: 152.12208557128906,
      y: 5.013936996459961,
    },
    {
      x: 152.56112670898438,
      y: 5.025912761688232,
    },
    {
      x: 153.00006103515625,
      y: 5.04148530960083,
    },
    {
      x: 153.4388427734375,
      y: 5.060589790344238,
    },
    {
      x: 153.87747192382812,
      y: 5.0831522941589355,
    },
    {
      x: 154.31590270996094,
      y: 5.109105110168457,
    },
    {
      x: 154.75413513183594,
      y: 5.138375759124756,
    },
    {
      x: 155.192138671875,
      y: 5.170904636383057,
    },
    {
      x: 155.6298828125,
      y: 5.2066264152526855,
    },
    {
      x: 156.0673828125,
      y: 5.245438098907471,
    },
    {
      x: 156.5045928955078,
      y: 5.287268161773682,
    },
    {
      x: 156.94149780273438,
      y: 5.332040786743164,
    },
    {
      x: 157.37811279296875,
      y: 5.3796796798706055,
    },
    {
      x: 157.81442260742188,
      y: 5.430109024047852,
    },
    {
      x: 158.25039672851562,
      y: 5.483256816864014,
    },
    {
      x: 158.68605041503906,
      y: 5.539045810699463,
    },
    {
      x: 159.12135314941406,
      y: 5.597401142120361,
    },
    {
      x: 159.5563201904297,
      y: 5.658253192901611,
    },
    {
      x: 159.99095153808594,
      y: 5.721524715423584,
    },
    {
      x: 160.42523193359375,
      y: 5.787141799926758,
    },
    {
      x: 160.85916137695312,
      y: 5.855034351348877,
    },
    {
      x: 161.29273986816406,
      y: 5.925129413604736,
    },
    {
      x: 161.72596740722656,
      y: 5.997355937957764,
    },
    {
      x: 162.15884399414062,
      y: 6.071659088134766,
    },
    {
      x: 162.59136962890625,
      y: 6.14797306060791,
    },
    {
      x: 163.02354431152344,
      y: 6.226219654083252,
    },
    {
      x: 163.45538330078125,
      y: 6.306328773498535,
    },
    {
      x: 163.88685607910156,
      y: 6.388384819030762,
    },
    {
      x: 164.3180389404297,
      y: 6.471988201141357,
    },
    {
      x: 164.74887084960938,
      y: 6.557253837585449,
    },
    {
      x: 165.1793975830078,
      y: 6.644163131713867,
    },
    {
      x: 165.60873413085938,
      y: 6.736725330352783,
    },
    {
      x: 166.03646850585938,
      y: 6.836402416229248,
    },
    {
      x: 166.462646484375,
      y: 6.942601680755615,
    },
    {
      x: 166.88720703125,
      y: 7.055054187774658,
    },
    {
      x: 167.31019592285156,
      y: 7.173302173614502,
    },
    {
      x: 167.73159790039062,
      y: 7.297082424163818,
    },
    {
      x: 168.15139770507812,
      y: 7.4261555671691895,
    },
    {
      x: 168.56961059570312,
      y: 7.560333728790283,
    },
    {
      x: 168.9862060546875,
      y: 7.69943380355835,
    },
    {
      x: 169.4011688232422,
      y: 7.843292236328125,
    },
    {
      x: 169.81446838378906,
      y: 7.9919023513793945,
    },
    {
      x: 170.22610473632812,
      y: 8.145050048828125,
    },
    {
      x: 170.63607788085938,
      y: 8.302604675292969,
    },
    {
      x: 171.04432678222656,
      y: 8.464591026306152,
    },
    {
      x: 171.45082092285156,
      y: 8.630897521972656,
    },
    {
      x: 171.8555908203125,
      y: 8.801383972167969,
    },
    {
      x: 172.25851440429688,
      y: 8.97618579864502,
    },
    {
      x: 172.65965270996094,
      y: 9.155025482177734,
    },
    {
      x: 173.0588836669922,
      y: 9.338092803955078,
    },
    {
      x: 173.4562530517578,
      y: 9.525154113769531,
    },
    {
      x: 173.8516845703125,
      y: 9.716306686401367,
    },
    {
      x: 174.24513244628906,
      y: 9.911498069763184,
    },
    {
      x: 174.63662719726562,
      y: 10.110596656799316,
    },
    {
      x: 175.02598571777344,
      y: 10.313830375671387,
    },
    {
      x: 175.41326904296875,
      y: 10.52097225189209,
    },
    {
      x: 175.79844665527344,
      y: 10.731999397277832,
    },
    {
      x: 176.18138122558594,
      y: 10.947097778320312,
    },
    {
      x: 176.5620880126953,
      y: 11.166110038757324,
    },
    {
      x: 176.9405059814453,
      y: 11.389002799987793,
    },
    {
      x: 177.316650390625,
      y: 11.6157865524292,
    },
    {
      x: 177.69029235839844,
      y: 11.846635818481445,
    },
    {
      x: 178.06150817871094,
      y: 12.08137035369873,
    },
    {
      x: 178.4302215576172,
      y: 12.319985389709473,
    },
    {
      x: 178.79640197753906,
      y: 12.562506675720215,
    },
    {
      x: 179.1599884033203,
      y: 12.808904647827148,
    },
    {
      x: 179.5207977294922,
      y: 13.059347152709961,
    },
    {
      x: 179.8788604736328,
      y: 13.313673973083496,
    },
    {
      x: 180.23411560058594,
      y: 13.571916580200195,
    },
    {
      x: 180.5865020751953,
      y: 13.834074020385742,
    },
    {
      x: 180.93594360351562,
      y: 14.100140571594238,
    },
    {
      x: 181.28236389160156,
      y: 14.370121955871582,
    },
    {
      x: 181.62571716308594,
      y: 14.644001960754395,
    },
    {
      x: 181.96591186523438,
      y: 14.921799659729004,
    },
    {
      x: 182.3028106689453,
      y: 15.203568458557129,
    },
    {
      x: 182.6363983154297,
      y: 15.48926067352295,
    },
    {
      x: 182.96658325195312,
      y: 15.778865814208984,
    },
    {
      x: 183.2933349609375,
      y: 16.07236099243164,
    },
    {
      x: 183.6165313720703,
      y: 16.3697509765625,
    },
    {
      x: 183.93609619140625,
      y: 16.67102813720703,
    },
    {
      x: 184.25196838378906,
      y: 16.9761905670166,
    },
    {
      x: 184.56407165527344,
      y: 17.285213470458984,
    },
    {
      x: 184.87229919433594,
      y: 17.598093032836914,
    },
    {
      x: 185.1765899658203,
      y: 17.914798736572266,
    },
    {
      x: 185.47683715820312,
      y: 18.235342025756836,
    },
    {
      x: 185.7729949951172,
      y: 18.559680938720703,
    },
    {
      x: 186.06494140625,
      y: 18.88780403137207,
    },
    {
      x: 186.35260009765625,
      y: 19.219680786132812,
    },
    {
      x: 186.63592529296875,
      y: 19.555286407470703,
    },
    {
      x: 186.91481018066406,
      y: 19.89458465576172,
    },
    {
      x: 187.1891632080078,
      y: 20.237552642822266,
    },
    {
      x: 187.45892333984375,
      y: 20.58414649963379,
    },
    {
      x: 187.7240447998047,
      y: 20.934297561645508,
    },
    {
      x: 187.98434448242188,
      y: 21.288053512573242,
    },
    {
      x: 188.2397918701172,
      y: 21.645334243774414,
    },
    {
      x: 188.49034118652344,
      y: 22.006057739257812,
    },
    {
      x: 188.73583984375,
      y: 22.370229721069336,
    },
    {
      x: 188.97628784179688,
      y: 22.73776626586914,
    },
    {
      x: 189.21160888671875,
      y: 23.10861587524414,
    },
    {
      x: 189.4417266845703,
      y: 23.482711791992188,
    },
    {
      x: 189.66659545898438,
      y: 23.8599796295166,
    },
    {
      x: 189.88604736328125,
      y: 24.240419387817383,
    },
    {
      x: 190.10012817382812,
      y: 24.623926162719727,
    },
    {
      x: 190.3087615966797,
      y: 25.010414123535156,
    },
    {
      x: 190.5119171142578,
      y: 25.399805068969727,
    },
    {
      x: 190.7094268798828,
      y: 25.792089462280273,
    },
    {
      x: 190.90133666992188,
      y: 26.187145233154297,
    },
    {
      x: 191.08763122558594,
      y: 26.584875106811523,
    },
    {
      x: 191.26817321777344,
      y: 26.98525047302246,
    },
    {
      x: 191.44297790527344,
      y: 27.388174057006836,
    },
    {
      x: 191.612060546875,
      y: 27.793521881103516,
    },
    {
      x: 191.7752685546875,
      y: 28.20128059387207,
    },
    {
      x: 191.93270874023438,
      y: 28.61128807067871,
    },
    {
      x: 192.0842742919922,
      y: 29.023509979248047,
    },
    {
      x: 192.22999572753906,
      y: 29.437837600708008,
    },
    {
      x: 192.36981201171875,
      y: 29.854190826416016,
    },
    {
      x: 192.50376892089844,
      y: 30.272464752197266,
    },
    {
      x: 192.6317901611328,
      y: 30.69260025024414,
    },
    {
      x: 192.7539825439453,
      y: 31.11446762084961,
    },
    {
      x: 192.8702392578125,
      y: 31.538000106811523,
    },
    {
      x: 192.98068237304688,
      y: 31.96307945251465,
    },
    {
      x: 193.08517456054688,
      y: 32.38967514038086,
    },
    {
      x: 193.18386840820312,
      y: 32.817649841308594,
    },
    {
      x: 193.27676391601562,
      y: 33.246910095214844,
    },
    {
      x: 193.36378479003906,
      y: 33.677406311035156,
    },
    {
      x: 193.44509887695312,
      y: 34.109012603759766,
    },
    {
      x: 193.5206756591797,
      y: 34.54166793823242,
    },
    {
      x: 193.5904541015625,
      y: 34.97529602050781,
    },
    {
      x: 193.6545867919922,
      y: 35.4097785949707,
    },
    {
      x: 193.713134765625,
      y: 35.84506607055664,
    },
    {
      x: 193.76609802246094,
      y: 36.281063079833984,
    },
    {
      x: 193.81346130371094,
      y: 36.717716217041016,
    },
    {
      x: 193.85533142089844,
      y: 37.154911041259766,
    },
    {
      x: 193.89175415039062,
      y: 37.59260177612305,
    },
    {
      x: 193.92279052734375,
      y: 38.03070831298828,
    },
    {
      x: 193.94851684570312,
      y: 38.46916198730469,
    },
    {
      x: 193.96897888183594,
      y: 38.90789031982422,
    },
    {
      x: 193.98419189453125,
      y: 39.346824645996094,
    },
    {
      x: 193.9942626953125,
      y: 39.785919189453125,
    },
    {
      x: 193.9991912841797,
      y: 40.22509002685547,
    },
    {
      x: 193.99911499023438,
      y: 40.664302825927734,
    },
    {
      x: 193.9940643310547,
      y: 41.10346221923828,
    },
    {
      x: 193.98411560058594,
      y: 41.54255676269531,
    },
    {
      x: 193.96929931640625,
      y: 41.98151397705078,
    },
    {
      x: 193.94973754882812,
      y: 42.420284271240234,
    },
    {
      x: 193.92547607421875,
      y: 42.85881423950195,
    },
    {
      x: 193.89657592773438,
      y: 43.29706573486328,
    },
    {
      x: 193.86300659179688,
      y: 43.73499298095703,
    },
    {
      x: 193.82496643066406,
      y: 44.172550201416016,
    },
    {
      x: 193.7825164794922,
      y: 44.60969161987305,
    },
    {
      x: 193.73573303222656,
      y: 45.04639434814453,
    },
    {
      x: 193.6845703125,
      y: 45.48261260986328,
    },
    {
      x: 193.629150390625,
      y: 45.918312072753906,
    },
    {
      x: 193.56964111328125,
      y: 46.35346221923828,
    },
    {
      x: 193.50601196289062,
      y: 46.78803253173828,
    },
    {
      x: 193.4382781982422,
      y: 47.221981048583984,
    },
    {
      x: 193.36663818359375,
      y: 47.655311584472656,
    },
    {
      x: 193.29104614257812,
      y: 48.08795166015625,
    },
    {
      x: 193.2116241455078,
      y: 48.519920349121094,
    },
    {
      x: 193.12828063964844,
      y: 48.95115661621094,
    },
    {
      x: 193.03854370117188,
      y: 49.3810920715332,
    },
    {
      x: 192.9417266845703,
      y: 49.809478759765625,
    },
    {
      x: 192.83773803710938,
      y: 50.23619079589844,
    },
    {
      x: 192.7262420654297,
      y: 50.661014556884766,
    },
    {
      x: 192.6073760986328,
      y: 51.08382034301758,
    },
    {
      x: 192.48097229003906,
      y: 51.504432678222656,
    },
    {
      x: 192.34683227539062,
      y: 51.922645568847656,
    },
    {
      x: 192.20506286621094,
      y: 52.33833694458008,
    },
    {
      x: 192.0553741455078,
      y: 52.75124740600586,
    },
    {
      x: 191.89791870117188,
      y: 53.1612434387207,
    },
    {
      x: 191.7324676513672,
      y: 53.568084716796875,
    },
    {
      x: 191.55908203125,
      y: 53.971614837646484,
    },
    {
      x: 191.37774658203125,
      y: 54.37163162231445,
    },
    {
      x: 191.18832397460938,
      y: 54.76786804199219,
    },
    {
      x: 190.99093627929688,
      y: 55.160213470458984,
    },
    {
      x: 190.7855987548828,
      y: 55.548458099365234,
    },
    {
      x: 190.57215881347656,
      y: 55.932308197021484,
    },
    {
      x: 190.35084533691406,
      y: 56.31166076660156,
    },
    {
      x: 190.12167358398438,
      y: 56.686317443847656,
    },
    {
      x: 189.88467407226562,
      y: 57.05610656738281,
    },
    {
      x: 189.639892578125,
      y: 57.42076110839844,
    },
    {
      x: 189.38746643066406,
      y: 57.7801628112793,
    },
    {
      x: 189.12750244140625,
      y: 58.1341667175293,
    },
    {
      x: 188.8601531982422,
      y: 58.48261642456055,
    },
    {
      x: 188.58551025390625,
      y: 58.825347900390625,
    },
    {
      x: 188.30374145507812,
      y: 59.16223907470703,
    },
    {
      x: 188.01498413085938,
      y: 59.4931640625,
    },
    {
      x: 187.71937561035156,
      y: 59.81800842285156,
    },
    {
      x: 187.4171142578125,
      y: 60.13664627075195,
    },
    {
      x: 187.10838317871094,
      y: 60.44901657104492,
    },
    {
      x: 186.79335021972656,
      y: 60.755035400390625,
    },
    {
      x: 186.47222900390625,
      y: 61.05466842651367,
    },
    {
      x: 186.1452178955078,
      y: 61.34784698486328,
    },
    {
      x: 185.8125,
      y: 61.63454055786133,
    },
    {
      x: 185.47425842285156,
      y: 61.914695739746094,
    },
    {
      x: 185.13070678710938,
      y: 62.1883430480957,
    },
    {
      x: 184.7820587158203,
      y: 62.455440521240234,
    },
    {
      x: 184.4285125732422,
      y: 62.71600341796875,
    },
    {
      x: 184.0702362060547,
      y: 62.97004318237305,
    },
    {
      x: 183.7074432373047,
      y: 63.21760559082031,
    },
    {
      x: 183.34036254882812,
      y: 63.45870590209961,
    },
    {
      x: 182.9691162109375,
      y: 63.69340896606445,
    },
    {
      x: 182.5939178466797,
      y: 63.92169952392578,
    },
    {
      x: 182.2149200439453,
      y: 64.1436767578125,
    },
    {
      x: 181.8323516845703,
      y: 64.3593978881836,
    },
    {
      x: 181.4463653564453,
      y: 64.56893920898438,
    },
    {
      x: 181.05711364746094,
      y: 64.77235412597656,
    },
    {
      x: 180.6647491455078,
      y: 64.96973419189453,
    },
    {
      x: 180.26947021484375,
      y: 65.1611557006836,
    },
    {
      x: 179.8713836669922,
      y: 65.34669494628906,
    },
    {
      x: 179.47064208984375,
      y: 65.52643585205078,
    },
    {
      x: 179.06736755371094,
      y: 65.70044708251953,
    },
    {
      x: 178.66172790527344,
      y: 65.86880493164062,
    },
    {
      x: 178.25381469726562,
      y: 66.03163146972656,
    },
    {
      x: 177.84375,
      y: 66.18893432617188,
    },
    {
      x: 177.43170166015625,
      y: 66.34099578857422,
    },
    {
      x: 177.0177764892578,
      y: 66.48779296875,
    },
    {
      x: 176.60198974609375,
      y: 66.62931823730469,
    },
    {
      x: 176.18450927734375,
      y: 66.76571655273438,
    },
    {
      x: 175.76541137695312,
      y: 66.89712524414062,
    },
    {
      x: 175.34481811523438,
      y: 67.02362060546875,
    },
    {
      x: 174.92282104492188,
      y: 67.14529418945312,
    },
    {
      x: 174.4994659423828,
      y: 67.2622299194336,
    },
    {
      x: 174.07485961914062,
      y: 67.37452697753906,
    },
    {
      x: 173.64906311035156,
      y: 67.48225402832031,
    },
    {
      x: 173.22218322753906,
      y: 67.58547973632812,
    },
    {
      x: 172.79421997070312,
      y: 67.68418884277344,
    },
    {
      x: 172.36526489257812,
      y: 67.77851867675781,
    },
    {
      x: 171.93540954589844,
      y: 67.86862182617188,
    },
    {
      x: 171.5046844482422,
      y: 67.95455932617188,
    },
    {
      x: 171.0731658935547,
      y: 68.03638458251953,
    },
    {
      x: 170.6409149169922,
      y: 68.11418151855469,
    },
    {
      x: 170.2079620361328,
      y: 68.18802642822266,
    },
    {
      x: 169.77435302734375,
      y: 68.25788879394531,
    },
    {
      x: 169.34011840820312,
      y: 68.32384490966797,
    },
    {
      x: 168.90533447265625,
      y: 68.38605499267578,
    },
    {
      x: 168.4700469970703,
      y: 68.44456481933594,
    },
    {
      x: 168.03428649902344,
      y: 68.49943542480469,
    },
    {
      x: 167.59808349609375,
      y: 68.55072021484375,
    },
    {
      x: 167.16148376464844,
      y: 68.59848022460938,
    },
    {
      x: 166.72451782226562,
      y: 68.64269256591797,
    },
    {
      x: 166.28720092773438,
      y: 68.68341827392578,
    },
    {
      x: 165.84959411621094,
      y: 68.72079467773438,
    },
    {
      x: 165.41171264648438,
      y: 68.75484466552734,
    },
    {
      x: 164.9735870361328,
      y: 68.78562927246094,
    },
    {
      x: 164.5352325439453,
      y: 68.81319427490234,
    },
    {
      x: 164.09671020507812,
      y: 68.83757781982422,
    },
    {
      x: 163.6580047607422,
      y: 68.85877227783203,
    },
    {
      x: 163.21917724609375,
      y: 68.8768081665039,
    },
    {
      x: 162.78024291992188,
      y: 68.89178466796875,
    },
    {
      x: 162.3411865234375,
      y: 68.90373992919922,
    },
    {
      x: 161.90208435058594,
      y: 68.91272735595703,
    },
    {
      x: 161.46292114257812,
      y: 68.91874694824219,
    },
    {
      x: 161.02371215820312,
      y: 68.9218521118164,
    },
    {
      x: 160.58450317382812,
      y: 68.92207336425781,
    },
    {
      x: 160.1453094482422,
      y: 68.91943359375,
    },
    {
      x: 159.7061309814453,
      y: 68.91388702392578,
    },
    {
      x: 159.2670135498047,
      y: 68.90553283691406,
    },
    {
      x: 158.82794189453125,
      y: 68.89439392089844,
    },
    {
      x: 158.3889617919922,
      y: 68.88050842285156,
    },
    {
      x: 157.9500732421875,
      y: 68.86388397216797,
    },
    {
      x: 157.51129150390625,
      y: 68.84454345703125,
    },
    {
      x: 157.07264709472656,
      y: 68.822509765625,
    },
    {
      x: 156.63412475585938,
      y: 68.79777526855469,
    },
    {
      x: 156.19577026367188,
      y: 68.77037811279297,
    },
    {
      x: 155.75759887695312,
      y: 68.74032592773438,
    },
    {
      x: 155.3196258544922,
      y: 68.70762634277344,
    },
    {
      x: 154.88185119628906,
      y: 68.67227935791016,
    },
    {
      x: 154.44427490234375,
      y: 68.63429260253906,
    },
    {
      x: 154.0069580078125,
      y: 68.59368133544922,
    },
    {
      x: 153.56988525390625,
      y: 68.55044555664062,
    },
    {
      x: 153.13307189941406,
      y: 68.50457000732422,
    },
    {
      x: 152.69654846191406,
      y: 68.4560775756836,
    },
    {
      x: 152.2603302001953,
      y: 68.40495300292969,
    },
    {
      x: 151.82443237304688,
      y: 68.35120391845703,
    },
    {
      x: 151.3888702392578,
      y: 68.29479217529297,
    },
    {
      x: 150.95364379882812,
      y: 68.23572540283203,
    },
    {
      x: 150.518798828125,
      y: 68.17396545410156,
    },
    {
      x: 150.08436584472656,
      y: 68.10944366455078,
    },
    {
      x: 149.65032958984375,
      y: 68.04220581054688,
    },
    {
      x: 149.21673583984375,
      y: 67.97222900390625,
    },
    {
      x: 148.78359985351562,
      y: 67.89949035644531,
    },
    {
      x: 148.35096740722656,
      y: 67.8238296508789,
    },
    {
      x: 147.91883850097656,
      y: 67.74527740478516,
    },
    {
      x: 147.48724365234375,
      y: 67.66385650634766,
    },
    {
      x: 147.05624389648438,
      y: 67.57935333251953,
    },
    {
      x: 146.6258544921875,
      y: 67.49176788330078,
    },
    {
      x: 146.1961212158203,
      y: 67.40104675292969,
    },
    {
      x: 145.76710510253906,
      y: 67.30699157714844,
    },
    {
      x: 145.33883666992188,
      y: 67.2096176147461,
    },
    {
      x: 144.91139221191406,
      y: 67.10870361328125,
    },
    {
      x: 144.48487854003906,
      y: 67.00386810302734,
    },
    {
      x: 144.05938720703125,
      y: 66.89493560791016,
    },
    {
      x: 143.63490295410156,
      y: 66.7822265625,
    },
    {
      x: 143.2113494873047,
      y: 66.666015625,
    },
    {
      x: 142.78868103027344,
      y: 66.54660034179688,
    },
    {
      x: 142.36688232421875,
      y: 66.4241943359375,
    },
    {
      x: 141.94593811035156,
      y: 66.29883575439453,
    },
    {
      x: 141.5258331298828,
      y: 66.1707992553711,
    },
    {
      x: 141.1064453125,
      y: 66.04032897949219,
    },
    {
      x: 140.6878662109375,
      y: 65.90728759765625,
    },
    {
      x: 140.26998901367188,
      y: 65.7720718383789,
    },
    {
      x: 139.85284423828125,
      y: 65.63462829589844,
    },
    {
      x: 139.43638610839844,
      y: 65.49510955810547,
    },
    {
      x: 139.02059936523438,
      y: 65.35356140136719,
    },
    {
      x: 138.60548400878906,
      y: 65.21015930175781,
    },
    {
      x: 138.19102478027344,
      y: 65.06480407714844,
    },
    {
      x: 137.7771453857422,
      y: 64.91781616210938,
    },
    {
      x: 137.3639373779297,
      y: 64.7689437866211,
    },
    {
      x: 136.95130920410156,
      y: 64.61848449707031,
    },
    {
      x: 136.5392303466797,
      y: 64.46652221679688,
    },
    {
      x: 136.12779235839844,
      y: 64.31280517578125,
    },
    {
      x: 135.71688842773438,
      y: 64.15766906738281,
    },
    {
      x: 135.30653381347656,
      y: 64.00113677978516,
    },
    {
      x: 134.896728515625,
      y: 63.84312057495117,
    },
    {
      x: 134.4875030517578,
      y: 63.68366622924805,
    },
    {
      x: 134.0787811279297,
      y: 63.52290725708008,
    },
    {
      x: 133.67056274414062,
      y: 63.360877990722656,
    },
    {
      x: 133.2628173828125,
      y: 63.197593688964844,
    },
    {
      x: 132.85565185546875,
      y: 63.032920837402344,
    },
    {
      x: 132.44895935058594,
      y: 62.86704635620117,
    },
    {
      x: 132.0427703857422,
      y: 62.70000457763672,
    },
    {
      x: 131.6370391845703,
      y: 62.531803131103516,
    },
    {
      x: 131.23179626464844,
      y: 62.36248779296875,
    },
    {
      x: 130.82701110839844,
      y: 62.19205093383789,
    },
    {
      x: 130.42266845703125,
      y: 62.02051544189453,
    },
    {
      x: 130.0188446044922,
      y: 61.84781265258789,
    },
    {
      x: 129.61546325683594,
      y: 61.674068450927734,
    },
    {
      x: 129.21253967285156,
      y: 61.49929428100586,
    },
    {
      x: 128.81004333496094,
      y: 61.3234977722168,
    },
    {
      x: 128.40798950195312,
      y: 61.146697998046875,
    },
    {
      x: 128.0063934326172,
      y: 60.968929290771484,
    },
    {
      x: 127.6052017211914,
      y: 60.79018020629883,
    },
    {
      x: 127.20443725585938,
      y: 60.610477447509766,
    },
    {
      x: 126.80409240722656,
      y: 60.42983627319336,
    },
    {
      x: 126.40416717529297,
      y: 60.248268127441406,
    },
    {
      x: 126.0046615600586,
      y: 60.0657844543457,
    },
    {
      x: 125.60557556152344,
      y: 59.882408142089844,
    },
    {
      x: 125.2068862915039,
      y: 59.698143005371094,
    },
    {
      x: 124.80860900878906,
      y: 59.513004302978516,
    },
    {
      x: 124.41072845458984,
      y: 59.32700729370117,
    },
    {
      x: 124.01325988769531,
      y: 59.140167236328125,
    },
    {
      x: 123.61616516113281,
      y: 58.952484130859375,
    },
    {
      x: 123.21946716308594,
      y: 58.76397705078125,
    },
    {
      x: 122.82315063476562,
      y: 58.57465744018555,
    },
    {
      x: 122.4272232055664,
      y: 58.38453674316406,
    },
    {
      x: 122.03168487548828,
      y: 58.193634033203125,
    },
    {
      x: 121.63650512695312,
      y: 58.00193786621094,
    },
    {
      x: 121.24174499511719,
      y: 57.80944061279297,
    },
    {
      x: 120.84736633300781,
      y: 57.61613845825195,
    },
    {
      x: 120.45335388183594,
      y: 57.42208480834961,
    },
    {
      x: 120.05970001220703,
      y: 57.22728729248047,
    },
    {
      x: 119.66642761230469,
      y: 57.03175735473633,
    },
    {
      x: 119.27349090576172,
      y: 56.83549499511719,
    },
    {
      x: 118.88093566894531,
      y: 56.638526916503906,
    },
    {
      x: 118.48873138427734,
      y: 56.440853118896484,
    },
    {
      x: 118.09687805175781,
      y: 56.242488861083984,
    },
    {
      x: 117.70539855957031,
      y: 56.04336929321289,
    },
    {
      x: 117.31430053710938,
      y: 55.843502044677734,
    },
    {
      x: 116.92354583740234,
      y: 55.64295959472656,
    },
    {
      x: 116.53314208984375,
      y: 55.44175720214844,
    },
    {
      x: 116.14306640625,
      y: 55.23989486694336,
    },
    {
      x: 115.75333404541016,
      y: 55.03738784790039,
    },
    {
      x: 115.36392211914062,
      y: 54.83424377441406,
    },
    {
      x: 114.97494506835938,
      y: 54.63032150268555,
    },
    {
      x: 114.58627319335938,
      y: 54.425750732421875,
    },
    {
      x: 114.19795227050781,
      y: 54.22057342529297,
    },
    {
      x: 113.8099365234375,
      y: 54.014774322509766,
    },
    {
      x: 113.42224884033203,
      y: 53.808380126953125,
    },
    {
      x: 113.03491973876953,
      y: 53.60131072998047,
    },
    {
      x: 112.64795684814453,
      y: 53.3935432434082,
    },
    {
      x: 112.26130676269531,
      y: 53.18518829345703,
    },
    {
      x: 111.87499237060547,
      y: 52.97627258300781,
    },
    {
      x: 111.48896789550781,
      y: 52.76677703857422,
    },
    {
      x: 111.10330200195312,
      y: 52.55662536621094,
    },
    {
      x: 110.7179946899414,
      y: 52.345802307128906,
    },
    {
      x: 110.3329849243164,
      y: 52.134422302246094,
    },
    {
      x: 109.94828796386719,
      y: 51.922508239746094,
    },
    {
      x: 109.56388854980469,
      y: 51.71005630493164,
    },
    {
      x: 109.17989349365234,
      y: 51.496864318847656,
    },
    {
      x: 108.79621124267578,
      y: 51.28312683105469,
    },
    {
      x: 108.4128189086914,
      y: 51.068870544433594,
    },
    {
      x: 108.02970123291016,
      y: 50.854095458984375,
    },
    {
      x: 107.64696502685547,
      y: 50.63864517211914,
    },
    {
      x: 107.26455688476562,
      y: 50.4226188659668,
    },
    {
      x: 106.8824234008789,
      y: 50.20609664916992,
    },
    {
      x: 106.50057983398438,
      y: 49.98908233642578,
    },
    {
      x: 106.11910247802734,
      y: 49.771400451660156,
    },
    {
      x: 105.73795318603516,
      y: 49.553165435791016,
    },
    {
      x: 105.35708618164062,
      y: 49.3344612121582,
    },
    {
      x: 104.97647857666016,
      y: 49.11528015136719,
    },
    {
      x: 104.59627532958984,
      y: 48.895389556884766,
    },
    {
      x: 104.21635437011719,
      y: 48.675018310546875,
    },
    {
      x: 103.8366928100586,
      y: 48.454185485839844,
    },
    {
      x: 103.45734405517578,
      y: 48.23283386230469,
    },
    {
      x: 103.07837677001953,
      y: 48.01083755493164,
    },
    {
      x: 102.69966888427734,
      y: 47.78839874267578,
    },
    {
      x: 102.32121276855469,
      y: 47.56552505493164,
    },
    {
      x: 101.94314575195312,
      y: 47.34199142456055,
    },
    {
      x: 101.56536865234375,
      y: 47.11796951293945,
    },
    {
      x: 101.18783569335938,
      y: 46.893531799316406,
    },
    {
      x: 100.81062316894531,
      y: 46.66856002807617,
    },
    {
      x: 100.43376159667969,
      y: 46.44298553466797,
    },
    {
      x: 100.05714416503906,
      y: 46.217010498046875,
    },
    {
      x: 99.6807861328125,
      y: 45.99060821533203,
    },
    {
      x: 99.30485534667969,
      y: 45.763526916503906,
    },
    {
      x: 98.92914581298828,
      y: 45.53604507446289,
    },
    {
      x: 98.55367279052734,
      y: 45.30817413330078,
    },
    {
      x: 98.17860412597656,
      y: 45.07963180541992,
    },
    {
      x: 97.80380249023438,
      y: 44.850685119628906,
    },
    {
      x: 97.42920684814453,
      y: 44.62135314941406,
    },
    {
      x: 97.05501556396484,
      y: 44.391395568847656,
    },
    {
      x: 96.68108367919922,
      y: 44.16099548339844,
    },
    {
      x: 96.30740356445312,
      y: 43.930259704589844,
    },
    {
      x: 95.93406677246094,
      y: 43.69889450073242,
    },
    {
      x: 95.56100463867188,
      y: 43.467079162597656,
    },
    {
      x: 95.18817138671875,
      y: 43.234928131103516,
    },
    {
      x: 94.81571197509766,
      y: 43.00216293334961,
    },
    {
      x: 94.44352722167969,
      y: 42.76897048950195,
    },
    {
      x: 94.071533203125,
      y: 42.53543472290039,
    },
    {
      x: 93.699951171875,
      y: 42.3012809753418,
    },
    {
      x: 93.32862854003906,
      y: 42.066749572753906,
    },
    {
      x: 92.9580307006836,
      y: 41.83102798461914,
    },
    {
      x: 92.58808898925781,
      y: 41.59429168701172,
    },
    {
      x: 92.21881103515625,
      y: 41.356510162353516,
    },
    {
      x: 91.8501968383789,
      y: 41.11770248413086,
    },
    {
      x: 91.48217010498047,
      y: 40.87797927856445,
    },
    {
      x: 91.11478424072266,
      y: 40.63729476928711,
    },
    {
      x: 90.7480239868164,
      y: 40.39567565917969,
    },
    {
      x: 90.3817138671875,
      y: 40.15334701538086,
    },
    {
      x: 90.01606750488281,
      y: 39.91002655029297,
    },
    {
      x: 89.65092468261719,
      y: 39.66596221923828,
    },
    {
      x: 89.28624725341797,
      y: 39.42118835449219,
    },
    {
      x: 88.9220199584961,
      y: 39.17573165893555,
    },
    {
      x: 88.55824279785156,
      y: 38.92962646484375,
    },
    {
      x: 88.19497680664062,
      y: 38.68276596069336,
    },
    {
      x: 87.83213806152344,
      y: 38.435264587402344,
    },
    {
      x: 87.46968841552734,
      y: 38.187217712402344,
    },
    {
      x: 87.10760498046875,
      y: 37.93864822387695,
    },
    {
      x: 86.745849609375,
      y: 37.68956756591797,
    },
    {
      x: 86.38447570800781,
      y: 37.439945220947266,
    },
    {
      x: 86.02344512939453,
      y: 37.189815521240234,
    },
    {
      x: 85.6626968383789,
      y: 36.93928527832031,
    },
    {
      x: 85.30221557617188,
      y: 36.688377380371094,
    },
    {
      x: 84.94197845458984,
      y: 36.437103271484375,
    },
    {
      x: 84.58199310302734,
      y: 36.18547058105469,
    },
    {
      x: 84.2223892211914,
      y: 35.933345794677734,
    },
    {
      x: 83.86280059814453,
      y: 35.681156158447266,
    },
    {
      x: 83.50337982177734,
      y: 35.4287109375,
    },
    {
      x: 83.14420318603516,
      y: 35.175926208496094,
    },
    {
      x: 82.7850341796875,
      y: 34.92314147949219,
    },
    {
      x: 82.42613983154297,
      y: 34.66998291015625,
    },
    {
      x: 82.0672607421875,
      y: 34.41679000854492,
    },
    {
      x: 81.70843505859375,
      y: 34.16348648071289,
    },
    {
      x: 81.3497314453125,
      y: 33.910064697265625,
    },
    {
      x: 80.99101257324219,
      y: 33.65663528442383,
    },
    {
      x: 80.63229370117188,
      y: 33.40321350097656,
    },
    {
      x: 80.27357482910156,
      y: 33.14978790283203,
    },
    {
      x: 79.91484832763672,
      y: 32.896366119384766,
    },
    {
      x: 79.5561294555664,
      y: 32.6429443359375,
    },
    {
      x: 79.1974105834961,
      y: 32.38951873779297,
    },
    {
      x: 78.83844757080078,
      y: 32.13644027709961,
    },
    {
      x: 78.47948455810547,
      y: 31.88338851928711,
    },
    {
      x: 78.12035369873047,
      y: 31.630538940429688,
    },
    {
      x: 77.76104736328125,
      y: 31.37795639038086,
    },
    {
      x: 77.4017333984375,
      y: 31.1253719329834,
    },
    {
      x: 77.0419692993164,
      y: 30.873403549194336,
    },
    {
      x: 76.68220520019531,
      y: 30.621484756469727,
    },
    {
      x: 76.32225036621094,
      y: 30.36983299255371,
    },
    {
      x: 75.96199035644531,
      y: 30.118608474731445,
    },
    {
      x: 75.60147094726562,
      y: 29.867753982543945,
    },
    {
      x: 75.24060821533203,
      y: 29.61738395690918,
    },
    {
      x: 74.87947082519531,
      y: 29.3674373626709,
    },
    {
      x: 74.51802062988281,
      y: 29.117918014526367,
    },
    {
      x: 74.15624237060547,
      y: 28.868864059448242,
    },
    {
      x: 73.79412841796875,
      y: 28.620319366455078,
    },
    {
      x: 73.43153381347656,
      y: 28.372438430786133,
    },
    {
      x: 73.06857299804688,
      y: 28.125123977661133,
    },
    {
      x: 72.7052230834961,
      y: 27.878389358520508,
    },
    {
      x: 72.34146118164062,
      y: 27.632265090942383,
    },
    {
      x: 71.97726440429688,
      y: 27.38677978515625,
    },
    {
      x: 71.61248779296875,
      y: 27.142173767089844,
    },
    {
      x: 71.24723052978516,
      y: 26.898269653320312,
    },
    {
      x: 70.88151550292969,
      y: 26.65511131286621,
    },
    {
      x: 70.5152587890625,
      y: 26.412683486938477,
    },
    {
      x: 70.14849853515625,
      y: 26.171035766601562,
    },
    {
      x: 69.78108215332031,
      y: 25.930410385131836,
    },
    {
      x: 69.4130859375,
      y: 25.690637588500977,
    },
    {
      x: 69.04450225830078,
      y: 25.451805114746094,
    },
    {
      x: 68.67526245117188,
      y: 25.213979721069336,
    },
    {
      x: 68.30540466308594,
      y: 24.97709083557129,
    },
    {
      x: 67.93485260009766,
      y: 24.74131202697754,
    },
    {
      x: 67.5636215209961,
      y: 24.50657844543457,
    },
    {
      x: 67.19171905517578,
      y: 24.272916793823242,
    },
    {
      x: 66.81903839111328,
      y: 24.040481567382812,
    },
    {
      x: 66.4457015991211,
      y: 23.809179306030273,
    },
    {
      x: 66.07156372070312,
      y: 23.57909393310547,
    },
    {
      x: 65.69664764404297,
      y: 23.350299835205078,
    },
    {
      x: 65.32099914550781,
      y: 23.12274169921875,
    },
    {
      x: 64.94452667236328,
      y: 22.896602630615234,
    },
    {
      x: 64.56720733642578,
      y: 22.671789169311523,
    },
    {
      x: 64.1890869140625,
      y: 22.448326110839844,
    },
    {
      x: 63.81007385253906,
      y: 22.226425170898438,
    },
    {
      x: 63.43021774291992,
      y: 22.005929946899414,
    },
    {
      x: 63.049476623535156,
      y: 21.786964416503906,
    },
    {
      x: 62.66780471801758,
      y: 21.569623947143555,
    },
    {
      x: 62.2852897644043,
      y: 21.35382652282715,
    },
    {
      x: 61.90177917480469,
      y: 21.139720916748047,
    },
    {
      x: 61.51734161376953,
      y: 20.927326202392578,
    },
    {
      x: 61.13199996948242,
      y: 20.716583251953125,
    },
    {
      x: 60.745628356933594,
      y: 20.507734298706055,
    },
    {
      x: 60.35831069946289,
      y: 20.300670623779297,
    },
    {
      x: 59.97003936767578,
      y: 20.095388412475586,
    },
    {
      x: 59.58066177368164,
      y: 19.892166137695312,
    },
    {
      x: 59.19033432006836,
      y: 19.690824508666992,
    },
    {
      x: 58.79898452758789,
      y: 19.491426467895508,
    },
    {
      x: 58.40657043457031,
      y: 19.294225692749023,
    },
    {
      x: 58.01313400268555,
      y: 19.099016189575195,
    },
    {
      x: 57.618648529052734,
      y: 18.90593719482422,
    },
    {
      x: 57.22303771972656,
      y: 18.71514320373535,
    },
    {
      x: 56.826412200927734,
      y: 18.5264949798584,
    },
    {
      x: 56.42869567871094,
      y: 18.34014129638672,
    },
    {
      x: 56.02985763549805,
      y: 18.156206130981445,
    },
    {
      x: 55.62997817993164,
      y: 17.974563598632812,
    },
    {
      x: 55.228981018066406,
      y: 17.795373916625977,
    },
    {
      x: 54.82683563232422,
      y: 17.618732452392578,
    },
    {
      x: 54.42365264892578,
      y: 17.44453239440918,
    },
    {
      x: 54.019351959228516,
      y: 17.27294921875,
    },
    {
      x: 53.61391830444336,
      y: 17.104076385498047,
    },
    {
      x: 53.2073974609375,
      y: 16.937774658203125,
    },
    {
      x: 52.79977035522461,
      y: 16.77424430847168,
    },
    {
      x: 52.39104461669922,
      y: 16.613460540771484,
    },
    {
      x: 51.98122024536133,
      y: 16.45560646057129,
    },
    {
      x: 51.57029724121094,
      y: 16.30060577392578,
    },
    {
      x: 51.158260345458984,
      y: 16.14853858947754,
    },
    {
      x: 50.745113372802734,
      y: 15.999489784240723,
    },
    {
      x: 50.33088684082031,
      y: 15.853453636169434,
    },
    {
      x: 49.91558837890625,
      y: 15.710517883300781,
    },
    {
      x: 49.49921417236328,
      y: 15.570752143859863,
    },
    {
      x: 49.0817756652832,
      y: 15.434142112731934,
    },
    {
      x: 48.663299560546875,
      y: 15.300802230834961,
    },
    {
      x: 48.24379348754883,
      y: 15.170768737792969,
    },
    {
      x: 47.8232536315918,
      y: 15.04404067993164,
    },
    {
      x: 47.40171432495117,
      y: 14.920744895935059,
    },
    {
      x: 46.97917175292969,
      y: 14.800883293151855,
    },
    {
      x: 46.555667877197266,
      y: 14.68447208404541,
    },
    {
      x: 46.13121032714844,
      y: 14.57165813446045,
    },
    {
      x: 45.70579528808594,
      y: 14.462395668029785,
    },
    {
      x: 45.279518127441406,
      y: 14.356733322143555,
    },
    {
      x: 44.852298736572266,
      y: 14.254805564880371,
    },
    {
      x: 44.42424011230469,
      y: 14.156562805175781,
    },
    {
      x: 43.995323181152344,
      y: 14.062041282653809,
    },
    {
      x: 43.56555938720703,
      y: 13.971383094787598,
    },
    {
      x: 43.13505172729492,
      y: 13.884530067443848,
    },
    {
      x: 42.70376205444336,
      y: 13.80152702331543,
    },
    {
      x: 42.27170944213867,
      y: 13.722496032714844,
    },
    {
      x: 41.83895492553711,
      y: 13.647377014160156,
    },
    {
      x: 41.40556716918945,
      y: 13.576220512390137,
    },
    {
      x: 40.97150421142578,
      y: 13.50914478302002,
    },
    {
      x: 40.53684997558594,
      y: 13.446084022521973,
    },
    {
      x: 40.10163497924805,
      y: 13.387063980102539,
    },
    {
      x: 39.66585922241211,
      y: 13.332215309143066,
    },
    {
      x: 39.22959899902344,
      y: 13.281476020812988,
    },
    {
      x: 38.79288864135742,
      y: 13.234858512878418,
    },
    {
      x: 38.355712890625,
      y: 13.19244384765625,
    },
    {
      x: 37.918212890625,
      y: 13.154248237609863,
    },
    {
      x: 37.48033142089844,
      y: 13.120233535766602,
    },
    {
      x: 37.0421257019043,
      y: 13.090417861938477,
    },
    {
      x: 36.603660583496094,
      y: 13.064925193786621,
    },
    {
      x: 36.16497802734375,
      y: 13.04366397857666,
    },
    {
      x: 35.726112365722656,
      y: 13.026642799377441,
    },
    {
      x: 35.287071228027344,
      y: 13.013872146606445,
    },
    {
      x: 34.84794998168945,
      y: 13.005454063415527,
    },
    {
      x: 34.40878677368164,
      y: 13.001297950744629,
    },
    {
      x: 33.96955490112305,
      y: 13.0014066696167,
    },
    {
      x: 33.53038024902344,
      y: 13.005782127380371,
    },
    {
      x: 33.091251373291016,
      y: 13.014447212219238,
    },
    {
      x: 32.65225601196289,
      y: 13.027426719665527,
    },
    {
      x: 32.213401794433594,
      y: 13.044857025146484,
    },
    {
      x: 31.774751663208008,
      y: 13.067183494567871,
    },
    {
      x: 31.33641242980957,
      y: 13.09467887878418,
    },
    {
      x: 30.898454666137695,
      y: 13.127233505249023,
    },
    {
      x: 30.46088218688965,
      y: 13.165119171142578,
    },
    {
      x: 30.023778915405273,
      y: 13.208248138427734,
    },
    {
      x: 29.587282180786133,
      y: 13.256887435913086,
    },
    {
      x: 29.15141487121582,
      y: 13.310977935791016,
    },
    {
      x: 28.716291427612305,
      y: 13.370651245117188,
    },
    {
      x: 28.281999588012695,
      y: 13.436050415039062,
    },
    {
      x: 27.848575592041016,
      y: 13.507181167602539,
    },
    {
      x: 27.416160583496094,
      y: 13.584132194519043,
    },
    {
      x: 26.98487091064453,
      y: 13.667034149169922,
    },
    {
      x: 26.55475425720215,
      y: 13.755962371826172,
    },
    {
      x: 26.125940322875977,
      y: 13.850958824157715,
    },
    {
      x: 25.698545455932617,
      y: 13.952095031738281,
    },
    {
      x: 25.27266502380371,
      y: 14.059453010559082,
    },
    {
      x: 24.848424911499023,
      y: 14.173097610473633,
    },
    {
      x: 24.42593002319336,
      y: 14.293095588684082,
    },
    {
      x: 24.00531005859375,
      y: 14.419503211975098,
    },
    {
      x: 23.586708068847656,
      y: 14.55236530303955,
    },
    {
      x: 23.170211791992188,
      y: 14.691739082336426,
    },
    {
      x: 22.75595474243164,
      y: 14.83765697479248,
    },
    {
      x: 22.344078063964844,
      y: 14.99020004272461,
    },
    {
      x: 21.934743881225586,
      y: 15.149385452270508,
    },
    {
      x: 21.528060913085938,
      y: 15.315200805664062,
    },
    {
      x: 21.124135971069336,
      y: 15.487665176391602,
    },
    {
      x: 20.723154067993164,
      y: 15.666872024536133,
    },
    {
      x: 20.325237274169922,
      y: 15.852749824523926,
    },
    {
      x: 19.930477142333984,
      y: 16.045303344726562,
    },
    {
      x: 19.539100646972656,
      y: 16.24459457397461,
    },
    {
      x: 19.151140213012695,
      y: 16.45049476623535,
    },
    {
      x: 18.76683235168457,
      y: 16.663127899169922,
    },
    {
      x: 18.3862361907959,
      y: 16.882305145263672,
    },
    {
      x: 18.009540557861328,
      y: 17.108129501342773,
    },
    {
      x: 17.63680648803711,
      y: 17.340471267700195,
    },
    {
      x: 17.268234252929688,
      y: 17.57927131652832,
    },
    {
      x: 16.903907775878906,
      y: 17.824554443359375,
    },
    {
      x: 16.543930053710938,
      y: 18.076181411743164,
    },
    {
      x: 16.188432693481445,
      y: 18.33407211303711,
    },
    {
      x: 15.83755111694336,
      y: 18.59823989868164,
    },
    {
      x: 15.491350173950195,
      y: 18.868532180786133,
    },
    {
      x: 15.149969100952148,
      y: 19.144840240478516,
    },
    {
      x: 14.813474655151367,
      y: 19.42709732055664,
    },
    {
      x: 14.481965065002441,
      y: 19.71519660949707,
    },
    {
      x: 14.155531883239746,
      y: 20.009029388427734,
    },
    {
      x: 13.834254264831543,
      y: 20.308507919311523,
    },
    {
      x: 13.518226623535156,
      y: 20.61349868774414,
    },
    {
      x: 13.207487106323242,
      y: 20.923887252807617,
    },
    {
      x: 12.902122497558594,
      y: 21.23954200744629,
    },
    {
      x: 12.602151870727539,
      y: 21.56037139892578,
    },
    {
      x: 12.30766773223877,
      y: 21.88623809814453,
    },
    {
      x: 12.018733024597168,
      y: 22.216976165771484,
    },
    {
      x: 11.735350608825684,
      y: 22.5524959564209,
    },
    {
      x: 11.457539558410645,
      y: 22.892684936523438,
    },
    {
      x: 11.185352325439453,
      y: 23.237390518188477,
    },
    {
      x: 10.918822288513184,
      y: 23.586463928222656,
    },
    {
      x: 10.657955169677734,
      y: 23.939794540405273,
    },
    {
      x: 10.402798652648926,
      y: 24.297304153442383,
    },
    {
      x: 10.15335464477539,
      y: 24.658784866333008,
    },
    {
      x: 9.909599304199219,
      y: 25.024147033691406,
    },
    {
      x: 9.671584129333496,
      y: 25.393272399902344,
    },
    {
      x: 9.43931770324707,
      y: 25.766014099121094,
    },
    {
      x: 9.212736129760742,
      y: 26.14226531982422,
    },
    {
      x: 8.991922378540039,
      y: 26.521930694580078,
    },
    {
      x: 8.776816368103027,
      y: 26.90483856201172,
    },
    {
      x: 8.567440032958984,
      y: 27.290929794311523,
    },
    {
      x: 8.363765716552734,
      y: 27.6800537109375,
    },
    {
      x: 8.165820121765137,
      y: 28.072128295898438,
    },
    {
      x: 7.973560810089111,
      y: 28.466989517211914,
    },
    {
      x: 7.787026405334473,
      y: 28.864627838134766,
    },
    {
      x: 7.606175899505615,
      y: 29.264848709106445,
    },
    {
      x: 7.430994510650635,
      y: 29.6676025390625,
    },
    {
      x: 7.261521339416504,
      y: 30.072803497314453,
    },
    {
      x: 7.097733020782471,
      y: 30.480323791503906,
    },
    {
      x: 6.939626693725586,
      y: 30.890079498291016,
    },
    {
      x: 6.787204265594482,
      y: 31.302001953125,
    },
    {
      x: 6.640493392944336,
      y: 31.71596336364746,
    },
    {
      x: 6.499485969543457,
      y: 32.13193130493164,
    },
    {
      x: 6.364209175109863,
      y: 32.54979705810547,
    },
    {
      x: 6.234694004058838,
      y: 32.96944808959961,
    },
    {
      x: 6.110942840576172,
      y: 33.39088821411133,
    },
    {
      x: 5.993049144744873,
      y: 33.8139533996582,
    },
    {
      x: 5.881011962890625,
      y: 34.238643646240234,
    },
    {
      x: 5.774908065795898,
      y: 34.664825439453125,
    },
    {
      x: 5.674816608428955,
      y: 35.09244155883789,
    },
    {
      x: 5.580816745758057,
      y: 35.52145767211914,
    },
    {
      x: 5.493001937866211,
      y: 35.95180892944336,
    },
    {
      x: 5.41151237487793,
      y: 36.38337707519531,
    },
    {
      x: 5.336495876312256,
      y: 36.816123962402344,
    },
    {
      x: 5.268125534057617,
      y: 37.2499885559082,
    },
    {
      x: 5.206632137298584,
      y: 37.68486785888672,
    },
    {
      x: 5.15227746963501,
      y: 38.12067413330078,
    },
    {
      x: 5.105386734008789,
      y: 38.557369232177734,
    },
    {
      x: 5.066342353820801,
      y: 38.99485397338867,
    },
    {
      x: 5.035667419433594,
      y: 39.4329719543457,
    },
    {
      x: 5.013955116271973,
      y: 39.87163162231445,
    },
    {
      x: 5.002040386199951,
      y: 40.3106803894043,
    },
    {
      x: 5.000575542449951,
      y: 40.74987030029297,
    },
    {
      x: 5.0063252449035645,
      y: 41.18904113769531,
    },
    {
      x: 5.01839542388916,
      y: 41.62809371948242,
    },
    {
      x: 5.036738395690918,
      y: 42.0669059753418,
    },
    {
      x: 5.061314105987549,
      y: 42.505401611328125,
    },
    {
      x: 5.0920844078063965,
      y: 42.94351577758789,
    },
    {
      x: 5.129016876220703,
      y: 43.381168365478516,
    },
    {
      x: 5.172078609466553,
      y: 43.8182373046875,
    },
    {
      x: 5.221249580383301,
      y: 44.254695892333984,
    },
    {
      x: 5.276525974273682,
      y: 44.69039535522461,
    },
    {
      x: 5.337908744812012,
      y: 45.12529754638672,
    },
    {
      x: 5.4053425788879395,
      y: 45.559303283691406,
    },
    {
      x: 5.478810787200928,
      y: 45.99231719970703,
    },
    {
      x: 5.558215618133545,
      y: 46.42428970336914,
    },
    {
      x: 5.643739700317383,
      y: 46.8550910949707,
    },
    {
      x: 5.7352705001831055,
      y: 47.284645080566406,
    },
    {
      x: 5.8327956199646,
      y: 47.71287155151367,
    },
    {
      x: 5.936347961425781,
      y: 48.13969421386719,
    },
    {
      x: 6.045917987823486,
      y: 48.56502151489258,
    },
    {
      x: 6.161470413208008,
      y: 48.98876190185547,
    },
    {
      x: 6.283044815063477,
      y: 49.410770416259766,
    },
    {
      x: 6.4106268882751465,
      y: 49.831031799316406,
    },
    {
      x: 6.544205665588379,
      y: 50.249427795410156,
    },
    {
      x: 6.683828353881836,
      y: 50.665863037109375,
    },
    {
      x: 6.829422950744629,
      y: 51.08023452758789,
    },
    {
      x: 6.981069564819336,
      y: 51.49241256713867,
    },
    {
      x: 7.138700008392334,
      y: 51.9023551940918,
    },
    {
      x: 7.302374839782715,
      y: 52.30992889404297,
    },
    {
      x: 7.4720377922058105,
      y: 52.715030670166016,
    },
    {
      x: 7.647741794586182,
      y: 53.1175651550293,
    },
    {
      x: 7.8294243812561035,
      y: 53.517417907714844,
    },
    {
      x: 8.0171480178833,
      y: 53.914485931396484,
    },
    {
      x: 8.210831642150879,
      y: 54.308677673339844,
    },
    {
      x: 8.410555839538574,
      y: 54.699851989746094,
    },
    {
      x: 8.616226196289062,
      y: 55.08794021606445,
    },
    {
      x: 8.827881813049316,
      y: 55.47276306152344,
    },
    {
      x: 9.045475006103516,
      y: 55.854251861572266,
    },
    {
      x: 9.268998146057129,
      y: 56.23231506347656,
    },
    {
      x: 9.498475074768066,
      y: 56.6068115234375,
    },
    {
      x: 9.733807563781738,
      y: 56.977630615234375,
    },
    {
      x: 9.975024223327637,
      y: 57.34468460083008,
    },
    {
      x: 10.222077369689941,
      y: 57.707801818847656,
    },
    {
      x: 10.474921226501465,
      y: 58.06694030761719,
    },
    {
      x: 10.733509063720703,
      y: 58.42193603515625,
    },
    {
      x: 10.997859954833984,
      y: 58.7726936340332,
    },
    {
      x: 11.26784610748291,
      y: 59.11908721923828,
    },
    {
      x: 11.543451309204102,
      y: 59.46105194091797,
    },
    {
      x: 11.824626922607422,
      y: 59.7984504699707,
    },
    {
      x: 12.111351013183594,
      y: 60.13117599487305,
    },
    {
      x: 12.403491020202637,
      y: 60.4591178894043,
    },
    {
      x: 12.701017379760742,
      y: 60.782203674316406,
    },
    {
      x: 13.00382137298584,
      y: 61.100303649902344,
    },
    {
      x: 13.311872482299805,
      y: 61.413360595703125,
    },
    {
      x: 13.625073432922363,
      y: 61.72123718261719,
    },
    {
      x: 13.943368911743164,
      y: 62.02388381958008,
    },
    {
      x: 14.266629219055176,
      y: 62.32118606567383,
    },
    {
      x: 14.594793319702148,
      y: 62.61309814453125,
    },
    {
      x: 14.92776107788086,
      y: 62.899539947509766,
    },
    {
      x: 15.265401840209961,
      y: 63.18040466308594,
    },
    {
      x: 15.607654571533203,
      y: 63.45566940307617,
    },
    {
      x: 15.954392433166504,
      y: 63.725257873535156,
    },
    {
      x: 16.305503845214844,
      y: 63.98909378051758,
    },
    {
      x: 16.6608829498291,
      y: 64.24714660644531,
    },
    {
      x: 17.02046012878418,
      y: 64.49935913085938,
    },
    {
      x: 17.384071350097656,
      y: 64.74568939208984,
    },
    {
      x: 17.751628875732422,
      y: 64.98612213134766,
    },
    {
      x: 18.123004913330078,
      y: 65.22063446044922,
    },
    {
      x: 18.498050689697266,
      y: 65.44920349121094,
    },
    {
      x: 18.87669563293457,
      y: 65.6717300415039,
    },
    {
      x: 19.258771896362305,
      y: 65.88827514648438,
    },
    {
      x: 19.644210815429688,
      y: 66.0988540649414,
    },
    {
      x: 20.032867431640625,
      y: 66.30339813232422,
    },
    {
      x: 20.424625396728516,
      y: 66.50194549560547,
    },
    {
      x: 20.819353103637695,
      y: 66.69451904296875,
    },
    {
      x: 21.21698760986328,
      y: 66.8810806274414,
    },
    {
      x: 21.617298126220703,
      y: 67.06172180175781,
    },
    {
      x: 22.020301818847656,
      y: 67.23634338378906,
    },
    {
      x: 22.425777435302734,
      y: 67.40512084960938,
    },
    {
      x: 22.833688735961914,
      y: 67.56795501708984,
    },
    {
      x: 23.24386978149414,
      y: 67.72496795654297,
    },
    {
      x: 23.656240463256836,
      y: 67.87616729736328,
    },
    {
      x: 24.070693969726562,
      y: 68.02153778076172,
    },
    {
      x: 24.487071990966797,
      y: 68.16120147705078,
    },
    {
      x: 24.905345916748047,
      y: 68.29520416259766,
    },
    {
      x: 25.32537841796875,
      y: 68.42355346679688,
    },
    {
      x: 25.74707794189453,
      y: 68.54629516601562,
    },
    {
      x: 26.170330047607422,
      y: 68.66350555419922,
    },
    {
      x: 26.59507942199707,
      y: 68.77526092529297,
    },
    {
      x: 27.02119255065918,
      y: 68.88160705566406,
    },
    {
      x: 27.44864845275879,
      y: 68.98259735107422,
    },
    {
      x: 27.87730598449707,
      y: 69.07830047607422,
    },
    {
      x: 28.307100296020508,
      y: 69.16877746582031,
    },
    {
      x: 28.737930297851562,
      y: 69.25406646728516,
    },
    {
      x: 29.169754028320312,
      y: 69.33428192138672,
    },
    {
      x: 29.60248374938965,
      y: 69.40946960449219,
    },
    {
      x: 30.036020278930664,
      y: 69.47970581054688,
    },
    {
      x: 30.4703426361084,
      y: 69.54505157470703,
    },
    {
      x: 30.905359268188477,
      y: 69.6055679321289,
    },
    {
      x: 31.341012954711914,
      y: 69.66128540039062,
    },
    {
      x: 31.777240753173828,
      y: 69.71234130859375,
    },
    {
      x: 32.2140007019043,
      y: 69.75880432128906,
    },
    {
      x: 32.651206970214844,
      y: 69.80068969726562,
    },
    {
      x: 33.088783264160156,
      y: 69.83808898925781,
    },
    {
      x: 33.5267448425293,
      y: 69.87129974365234,
    },
    {
      x: 33.9649658203125,
      y: 69.90061950683594,
    },
    {
      x: 34.4034309387207,
      y: 69.92613983154297,
    },
    {
      x: 34.84208679199219,
      y: 69.94784545898438,
    },
    {
      x: 35.28094482421875,
      y: 69.96575927734375,
    },
    {
      x: 35.71992492675781,
      y: 69.97989654541016,
    },
    {
      x: 36.15898895263672,
      y: 69.99027252197266,
    },
    {
      x: 36.59815979003906,
      y: 69.99693298339844,
    },
    {
      x: 37.037353515625,
      y: 69.99984741210938,
    },
    {
      x: 37.4765739440918,
      y: 69.99908447265625,
    },
    {
      x: 37.91574478149414,
      y: 69.99461364746094,
    },
    {
      x: 38.3548698425293,
      y: 69.98656463623047,
    },
    {
      x: 38.793941497802734,
      y: 69.9747543334961,
    },
    {
      x: 39.232879638671875,
      y: 69.95948028564453,
    },
    {
      x: 39.67166519165039,
      y: 69.940673828125,
    },
    {
      x: 40.11027145385742,
      y: 69.91814422607422,
    },
    {
      x: 40.54869842529297,
      y: 69.89213562011719,
    },
    {
      x: 40.986915588378906,
      y: 69.86266326904297,
    },
    {
      x: 41.42490005493164,
      y: 69.82976531982422,
    },
    {
      x: 41.86258316040039,
      y: 69.79340362548828,
    },
    {
      x: 42.29999542236328,
      y: 69.75350189208984,
    },
    {
      x: 42.737056732177734,
      y: 69.71024322509766,
    },
    {
      x: 43.173770904541016,
      y: 69.66364288330078,
    },
    {
      x: 43.61015701293945,
      y: 69.61373138427734,
    },
    {
      x: 44.04613494873047,
      y: 69.56053924560547,
    },
    {
      x: 44.48168182373047,
      y: 69.50396728515625,
    },
    {
      x: 44.916770935058594,
      y: 69.4441146850586,
    },
    {
      x: 45.3514404296875,
      y: 69.38105773925781,
    },
    {
      x: 45.78560256958008,
      y: 69.3148193359375,
    },
    {
      x: 46.21931076049805,
      y: 69.24542236328125,
    },
    {
      x: 46.65250015258789,
      y: 69.17289733886719,
    },
    {
      x: 47.08512496948242,
      y: 69.09725952148438,
    },
    {
      x: 47.51718521118164,
      y: 69.01844024658203,
    },
    {
      x: 47.94867706298828,
      y: 68.93656921386719,
    },
    {
      x: 48.37960433959961,
      y: 68.85169219970703,
    },
    {
      x: 48.80994415283203,
      y: 68.7638168334961,
    },
    {
      x: 49.23963928222656,
      y: 68.67298126220703,
    },
    {
      x: 49.668739318847656,
      y: 68.57917785644531,
    },
    {
      x: 50.097171783447266,
      y: 68.48247528076172,
    },
    {
      x: 50.52491760253906,
      y: 68.38289642333984,
    },
    {
      x: 50.95201110839844,
      y: 68.28043365478516,
    },
    {
      x: 51.3784065246582,
      y: 68.17513275146484,
    },
    {
      x: 51.80410385131836,
      y: 68.06697845458984,
    },
    {
      x: 52.229061126708984,
      y: 67.95606231689453,
    },
    {
      x: 52.6533203125,
      y: 67.84239196777344,
    },
    {
      x: 53.076805114746094,
      y: 67.72599029541016,
    },
    {
      x: 53.499568939208984,
      y: 67.60688781738281,
    },
    {
      x: 53.92156219482422,
      y: 67.48511505126953,
    },
    {
      x: 54.342742919921875,
      y: 67.3607177734375,
    },
    {
      x: 54.76317596435547,
      y: 67.23368072509766,
    },
    {
      x: 55.18281936645508,
      y: 67.10404205322266,
    },
    {
      x: 55.601661682128906,
      y: 66.97183990478516,
    },
    {
      x: 56.01968002319336,
      y: 66.83709716796875,
    },
    {
      x: 56.43688201904297,
      y: 66.6998291015625,
    },
    {
      x: 56.85326385498047,
      y: 66.56006622314453,
    },
    {
      x: 57.268798828125,
      y: 66.41783905029297,
    },
    {
      x: 57.683494567871094,
      y: 66.27316284179688,
    },
    {
      x: 58.09736251831055,
      y: 66.12606811523438,
    },
    {
      x: 58.51032257080078,
      y: 65.97653198242188,
    },
    {
      x: 58.922428131103516,
      y: 65.82462310791016,
    },
    {
      x: 59.33365249633789,
      y: 65.67036437988281,
    },
    {
      x: 59.743995666503906,
      y: 65.51378631591797,
    },
    {
      x: 60.1534538269043,
      y: 65.35490417480469,
    },
    {
      x: 60.56201934814453,
      y: 65.19374084472656,
    },
    {
      x: 60.96971130371094,
      y: 65.03032684326172,
    },
    {
      x: 61.37643814086914,
      y: 64.86466979980469,
    },
    {
      x: 61.78226089477539,
      y: 64.69673156738281,
    },
    {
      x: 62.187198638916016,
      y: 64.526611328125,
    },
    {
      x: 62.59120559692383,
      y: 64.35433959960938,
    },
    {
      x: 62.99430465698242,
      y: 64.17992401123047,
    },
    {
      x: 63.39643859863281,
      y: 64.0032730102539,
    },
    {
      x: 63.79762649536133,
      y: 63.824546813964844,
    },
    {
      x: 64.1978988647461,
      y: 63.64375686645508,
    },
    {
      x: 64.59719848632812,
      y: 63.460819244384766,
    },
    {
      x: 64.99554443359375,
      y: 63.27582931518555,
    },
    {
      x: 65.39295959472656,
      y: 63.088836669921875,
    },
    {
      x: 65.78937530517578,
      y: 62.89972686767578,
    },
    {
      x: 66.18486022949219,
      y: 62.70867156982422,
    },
    {
      x: 66.57926940917969,
      y: 62.515480041503906,
    },
    {
      x: 66.97286224365234,
      y: 62.32053756713867,
    },
    {
      x: 67.36534118652344,
      y: 62.12343215942383,
    },
    {
      x: 67.75686645507812,
      y: 61.92441940307617,
    },
    {
      x: 68.14744567871094,
      y: 61.72357940673828,
    },
    {
      x: 68.53704071044922,
      y: 61.52076721191406,
    },
    {
      x: 68.92557525634766,
      y: 61.31599044799805,
    },
    {
      x: 69.31318664550781,
      y: 61.109458923339844,
    },
    {
      x: 69.69976043701172,
      y: 60.90093231201172,
    },
    {
      x: 70.08531188964844,
      y: 60.690616607666016,
    },
    {
      x: 70.46989440917969,
      y: 60.478485107421875,
    },
    {
      x: 70.85345458984375,
      y: 60.26445007324219,
    },
    {
      x: 71.23600006103516,
      y: 60.048709869384766,
    },
    {
      x: 71.6175308227539,
      y: 59.83112335205078,
    },
    {
      x: 71.99805450439453,
      y: 59.611785888671875,
    },
    {
      x: 72.37760162353516,
      y: 59.39078903198242,
    },
    {
      x: 72.75606536865234,
      y: 59.16795349121094,
    },
    {
      x: 73.13359832763672,
      y: 58.9434814453125,
    },
    {
      x: 73.5101547241211,
      y: 58.717384338378906,
    },
    {
      x: 73.8857192993164,
      y: 58.489707946777344,
    },
    {
      x: 74.26033020019531,
      y: 58.26045227050781,
    },
    {
      x: 74.63407135009766,
      y: 58.029747009277344,
    },
    {
      x: 75.00696563720703,
      y: 57.79772186279297,
    },
    {
      x: 75.37918090820312,
      y: 57.56454849243164,
    },
    {
      x: 75.75081634521484,
      y: 57.33048629760742,
    },
    {
      x: 76.12228393554688,
      y: 57.09615707397461,
    },
    {
      x: 76.49407196044922,
      y: 56.8622932434082,
    },
    {
      x: 76.8676986694336,
      y: 56.63140106201172,
    },
  ],
}
