import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const usePatientsStore = create(() => ({
  currentPatientHealthProfile: null,
  loadingHealthProfile: false,
}))

export const useLocalisationStore = create(() => ({
  isLanguageSelectionEnabled: false,
}))

export const useLocationStore = create(() => ({
  locations: [],
  selectedLocation: undefined,
  loading: false,
  hasFailed: false,
  secondaryLocations: undefined,
}))

export const useTodaysAppointmentsStore = create(() => ({
  todaysAppointments: undefined,
  professionals: undefined,
  patients: undefined,
  unknownAppointments: undefined,
  syncSearchUserData: {},
  checkinData: {},
  anamnesisData: {},
  professionalMappings: undefined,
  professionalFormTemplates: undefined,
  hasFailed: false,
  showLegacyCheckinModal: false,
  showNewManualCheckinModal: false,
  showLegacyManualCheckinModal: false,
  showGlobalManualCheckinModal: false,
  usePcsForGlobalManualCheckin: false,
  selectedForms: {},
  requiredForms: {},
  isAnamnesisSelectedForm: {},
  code: null,
  appointmentTicketNumber: {},
  isTodaysAppointmentsLoading: true,
  isCheckinDataLoading: true,
  isPatientDataLoading: true,
}))

export const usePractitionersMappingStore = create(() => ({
  showAddMappingModal: false,
  professionals: undefined,
  professionalMappings: undefined,
  calendars: undefined,
  mapping: undefined,
}))

export const useAppointmentsMappingStore = create(() => ({
  showAddMappingModal: false,
  scheduleMappings: undefined,
  scheduleTypes: undefined,
  mapping: undefined,
}))
export const usePatientCallSystemStore = create(
  persist(
    () => ({
      authToken: {
        token: null,
        validUntil: 0,
        instanceId: '',
      },
      selectedPrinter: {},
    }),
    {
      name: 'patient-call-system-storage',
    },
  ),
)

export const usePowerUserStore = create(() => ({
  lastUsedCert: null,
  showCertModal: false,
}))

export const useAdminConfigStore = create(() => ({
  renderedPages: undefined,
  featureAccess: {},
  customers: null,
}))

export const useAdminUsersStore = create(() => ({
  users: {},
  currentlyUpdatingUsers: {},
}))

export const useHeraklesStore = create(() => ({
  loading: true,
  patients: [],
  isAccessDenied: false,
  hasFailed: false,
  patientDetails: {},
}))

export const useAnamnesisBuilderStore = create(() => ({
  problems: [],
  cachedProfessionals: null,
}))

export const useProfessionalsStore = create(() => ({
  templateArray: [],
  finalRecords: [],
  childData: null,
  cachedUserData: null,
}))

const initialPrismicStore = {
  // Prismic caching
  appVersion: null,
  timestamp: undefined,
  ref: undefined,

  // Users
  userScopes: null,
  userGroups: null,

  // Today's appointments
  todaysAppointmentsPrismicData: undefined,
  professionals: undefined,

  // Location
  instanceConfigs: undefined,

  // Anamnesis Builder
  questions: {},
  subQuestions: {},
  allQuestions: {},
  appointmentTypes: null,

  // Professionals
  consentFormDocuments: null,

  // Data Assets
  labels: [],

  // Whitelabel
  allCustomers: null,
  currentCustomer: null,
  locationHubs: [],

  // Forms
  treatmentContract: null,
}

export const usePrismicStore = create(
  persist(
    (set) => ({
      ...initialPrismicStore,
      resetStore: () => set({ ...initialPrismicStore }),
    }),
    { name: 'prismic-storage' },
  ),
)
