// action - account reducer
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const CLEAR_OBJECTS = 'CLEAR_OBJECTS'
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SET_USER_SCOPE = 'SET_USER_SCOPE'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const SET_TO_DEFAULT = 'SET_TO_DEFAULT'

//patient
export const GET_ALL_PATIENT_SUCCESS = 'GET_ALL_PATIENT_SUCCESS'
export const GET_ALL_PATIENT_FAIL = 'GET_ALL_PATIENT_FAIL'
export const GET_PATIENT_BY_ID_SUCCESS = 'GET_PATIENT_BY_ID_SUCCESS'
export const GET_PATIENT_BY_ID_FAIL = 'GET_PATIENT_BY_ID_FAIL'
export const GET_PATIENT_ACCOUNT_DETAILS_SUCCESS = 'GET_PATIENT_ACCOUNT_DETAILS_SUCCESS'
export const GET_PATIENT_ACCOUNT_DETAILS_FAIL = 'GET_PATIENT_ACCOUNT_DETAILS_FAIL'
export const GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS = 'GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS'
export const GET_PATIENT_APPOINTMENT_DETAILS_FAIL = 'GET_PATIENT_APPOINTMENT_DETAILS_FAIL'
export const GET_PATIENT_HEALTH_PROFILE_DETAILS_SUCCESS =
  'GET_PATIENT_HEALTH_PROFILE_DETAILS_SUCCESS'
export const GET_PATIENT_HEALTH_PROFILE_DETAILS_FAIL = 'GET_PATIENT_HEALTH_PROFILE_DETAILS_FAIL'
export const UPDATE_PATIENT_ACCOUNT_INFORMATION_SUCCESS =
  'UPDATE_PATIENT_ACCOUNT_INFORMATION_SUCCESS'
export const UPDATE_PATIENT_ACCOUNT_INFORMATION_FAIL = 'UPDATE_PATIENT_ACCOUNT_INFORMATION_FAIL'
export const UPDATE_PATIENT_BASIC_INFO_SUCCESS = 'UPDATE_PATIENT_BASIC_INFO_SUCCESS'
export const UPDATE_PATIENT_BASIC_INFO_FAIL = 'UPDATE_PATIENT_BASIC_INFO_FAIL'
export const UPDATE_PATIENT_ADDRESS_INFO_SUCCESS = 'UPDATE_PATIENT_ADDRESS_INFO_SUCCESS'
export const UPDATE_PATIENT_ADDRESS_INFO_FAIL = 'UPDATE_PATIENT_ADDRESS_INFO_FAIL'
export const UPDATE_PATIENT_HEALTH_INFO_SUCCESS = 'UPDATE_PATIENT_HEALTH_INFO_SUCCESS'
export const UPDATE_PATIENT_HEALTH_INFO_FAIL = 'UPDATE_PATIENT_HEALTH_INFO_FAIL'
export const SYNC_PATIENT_DATA_SUCCESS = 'SYNC_PATIENT_DATA_SUCCESS'
export const SYNC_PATIENT_DATA_FAIL = 'SYNC_PATIENT_DATA_FAIL'
export const GET_PATIENT_ADDRESS_DETAILS_SUCCESS = 'GET_PATIENT_ADDRESS_DETAILS_SUCCESS'
export const GET_PATIENT_ADDRESS_DETAILS_FAIL = 'GET_PATIENT_ADDRESS_DETAILS_FAIL'
export const POST_PATIENT_DATA_SUCCESS = 'POST_PATIENT_DATA_SUCCESS'
export const POST_PATIENT_DATA_FAIL = 'POST_PATIENT_DATA_FAIL'
//professional
export const GET_ALL_PROFESSIONALS_REQUEST = 'GET_ALL_PROFESSIONALS_REQUEST'
export const GET_ALL_PROFESSIONALS_SUCCESS = 'GET_ALL_PROFESSIONALS_SUCCESS'
export const GET_ALL_PROFESSIONALS_FAIL = 'GET_ALL_PROFESSIONALS_FAIL'
export const GET_ONBOARDING_DATA_SUCCESS = 'GET_ONBOARDING_DATA_SUCCESS'
export const GET_ONBOARDING_DATA_FAIL = 'GET_ONBOARDING_DATA_FAIL'
export const GET_PROFESSIONAL_BY_ID_SUCCESS = 'GET_PROFESSIONAL_BY_ID_SUCCESS'
export const GET_PROFESSIONAL_BY_ID_FAIL = 'GET_PROFESSIONAL_BY_ID_FAIL'
export const UPDATE_PROFESSIONAL_BY_ID_SUCCESS = 'UPDATE_PROFESSIONAL_BY_ID_SUCCESS'
export const UPDATE_PROFESSIONAL_BY_ID_FAIL = 'UPDATE_PROFESSIONAL_BY_ID_FAIL'
export const SYNC_PROFESSIONAL_DATA_SUCCESS = 'SYNC_PROFESSIONAL_DATA_SUCCESS'
export const SYNC_PROFESSIONAL_DATA_FAIL = 'SYNC_PROFESSIONAL_DATA_FAIL'
export const POST_PROFESSIONAL_DATA_SUCCESS = 'POST_PROFESSIONAL_DATA_SUCCESS'
export const POST_PROFESSIONAL_DATA_FAIL = 'POST_PROFESSIONAL_DATA_FAIL'
export const GET_PROFESSIONAL_TEMPLATE_REQUEST = 'GET_PROFESSIONAL_TEMPLATE_REQUEST'
export const GET_PROFESSIONAL_TEMPLATE_SUCCESS = 'GET_PROFESSIONAL_TEMPLATE_SUCCESS'
export const GET_PROFESSIONAL_TEMPLATE_ANA_SUCCESS = 'GET_PROFESSIONAL_TEMPLATE_ANA_SUCCESS'
export const GET_PROFESSIONAL_TEMPLATE_FAIL = 'GET_PROFESSIONAL_TEMPLATE_FAIL'

//appointments
export const GET_ALL_TODAYS_APPOINTMENTS_SUCCESS = 'GET_ALL_TODAYS_APPOINTMENTS_SUCCESS'
export const GET_ALL_TODAYS_APPOINTMENTS_FAIL = 'GET_ALL_TODAYS_APPOINTMENTS_FAIL'
export const GET_ALL_APPOINTMENTS_SUCCESS = 'GET_ALL_APPOINTMENTS_SUCCESS'
export const GET_ALL_APPOINTMENTS_FAIL = 'GET_ALL_APPOINTMENTS_FAIL'
export const UPDATE_USER_BY_NUMBER_SUCCESS = 'UPDATE_USER_BY_NUMBER_SUCCESS'
export const UPDATE_USER_BY_NUMBER_FAIL = 'UPDATE_USER_BY_NUMBER_FAIL'
export const CLEAR_CHECK_IN_SUCCESS = 'CLEAR_CHECK_IN_SUCCESS'
export const GENERATE_CODE_REQUEST = 'GENERATE_CODE_REQUEST'
export const GENERATE_CODE_SUCCESS = 'GENERATE_CODE_SUCCESS'
export const GENERATE_CODE_FAIL = 'GENERATE_CODE_FAIL'
export const SAVE_APPOINTMENT_REQUEST = 'SAVE_APPOINTMENT_REQUEST'
export const SAVE_APPOINTMENT_SUCCESS = 'SAVE_APPOINTMENT_SUCCESS'
export const SAVE_APPOINTMENT_FAIL = 'SAVE_APPOINTMENT_FAIL'
export const GET_SELECTED_APPOINTMENT_REQUEST = 'GET_SELECTED_APPOINTMENT_REQUEST'
export const GET_SELECTED_APPOINTMENT_SUCCESS = 'GET_SELECTED_APPOINTMENT_SUCCESS'
export const GET_SELECTED_APPOINTMENT_FAIL = 'GET_SELECTED_APPOINTMENT_FAIL'
//DcProfessional
export const GET_ALL_DC_PROFESSIONALS_SUCCESS = 'GET_ALL_DC_PROFESSIONALS_SUCCESS'
export const GET_ALL_DC_PROFESSIONALS_FAIL = 'GET_ALL_DC_PROFESSIONALS_FAIL'
export const GET_DC_PROFESSIONAL_BY_ID_SUCCESS = 'GET_PROFESSIONAL_BY_ID_SUCCESS'
export const GET_DC_PROFESSIONAL_BY_ID_FAIL = 'GET_PROFESSIONAL_BY_ID_FAIL'
export const GET_DC_PROFESSIONAL_MAPPING_BY_ID_SUCCESS = 'GET_DC_PROFESSIONAL_MAPPING_BY_ID_SUCCESS'
export const GET_DC_PROFESSIONAL_MAPPING_BY_ID_FAIL = 'GET_DC_PROFESSIONAL_MAPPING_BY_ID_FAIL'
export const GET_DC_PROFESSIONAL_MAPPINGS_SUCCESS = 'GET_DC_PROFESSIONAL_MAPPINGS_SUCCESS'
export const GET_DC_PROFESSIONAL_MAPPINGS_FAIL = 'GET_DC_PROFESSIONAL_MAPPINGS_SUCCESS'
//DCPatient
export const GET_ALL_DC_PATIENTS_SUCCESS = 'GET_ALL_DC_PATIENTS_SUCCESS'
export const GET_ALL_DC_PATIENTS_FAIL = 'GET_ALL_DC_PATIENTS_FAIL'
export const GET_DC_PATIENTS_IDS_SUCCESS = 'GET_DC_PATIENTS_IDS_SUCCESS'
export const GET_DC_PATIENTS_IDS_FAIL = 'GET_DC_PATIENTS_IDS_FAIL'
export const GET_DC_PATIENT_BY_ID_SUCCESS = 'GET_DC_PATIENT_BY_ID_SUCCESS'
export const GET_DC_PATIENT_BY_ID_FAIL = 'GET_DC_PATIENT_BY_ID_FAIL'
export const GET_DC_PATIENT_MAPPINGS_SUCCESS = 'GET_DC_PATIENT_MAPPINGS_SUCCESS'
export const GET_DC_PATIENT_MAPPINGS_FAIL = 'GET_DC_PATIENT_MAPPINGS_SUCCESS'
//DCAppointments
export const GET_ALL_DC_APPOINTMENTS_SUCCESS = 'GET_ALL_DC_APPOINTMENTS_SUCCESS'
export const GET_ALL_DC_APPOINTMENTS_FAIL = 'GET_ALL_DC_APPOINTMENTS_FAIL'
export const GET_DC_APPOINTMENT_TYPES_BY_ID_SUCCESS = 'GET_DC_APPOINTMENT_TYPES_BY_ID_SUCCESS'
export const GET_DC_APPOINTMENT_TYPES_BY_ID_FAIL = 'GET_DC_APPOINTMENT_TYPES_BY_ID_FAIL'
//DCCalendars
export const GET_ALL_DC_CALENDAR_SUCCESS = 'GET_ALL_DC_CALENDAR_SUCCESS'
export const GET_ALL_DC_CALENDAR_FAIL = 'GET_ALL_DC_CALENDAR_FAIL'
export const GET_CALENDAR_BY_ID_SUCCESS = 'GET_CALENDAR_BY_ID_SUCCESS'
export const GET_CALENDAR_BY_ID_FAIL = 'GET_CALENDAR_BY_ID_FAIL'
//DCPatAppointment
export const GET_ALL_DC_PATIENTS_APPOINTMENTS_SUCCESS = 'GET_ALL_DC_PATIENTS_APPOINTMENTS_SUCCESS'
export const GET_ALL_DC_PATIENTS_APPOINTMENTS_FAIL = 'GET_ALL_DC_PATIENTS_APPOINTMENTS_FAIL'
// PatUserHealthProfile
export const GET_PAT_USER_BY_ID_HEALTH_PROFILE_SUCCESS = 'GET_PAT_USER_BY_ID_HEALTH_PROFILE_SUCCESS'
export const GET_PAT_USER_BY_ID_HEALTH_PROFILE_FAIL = 'GET_PAT_USER_BY_ID_HEALTH_PROFILE_FAIL'

// PracUsers
export const GET_ALL_CACHED_USER_DATA_SUCCESS = 'GET_ALL_CACHED_USER_DATA_SUCCESS'
export const GET_ALL_CACHED_USER_DATA_FAIL = 'GET_ALL_CACHED_USER_DATA_FAIL'
export const CREATE_FORM_DATA_SUCCESS = 'CREATE_FORM_DATA_SUCCESS'
export const CREATE_FORM_DATA_FAIL = 'CREATE_FORM_DATA_FAIL'
