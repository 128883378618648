import axios from 'axios'

const BASE_URL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/`

export async function getById(userToken, id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken,
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}user/health-profiles/${id}`, config)
    console.debug(res)
    return res
  } catch (error) {
    console.debug(error)
    return error
  }
}
