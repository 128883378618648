import axios from 'axios'
import { getCustomerId } from '../../utils/helpers'

const BASE_URL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PRACTITIONER_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/`
const BASE_URL_PATIENT = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/`

export async function getAllPractitioner(userToken) {
  const config = {
    headers: {
      Authorization: userToken,
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function getData(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}user/onboarding-data/${id}`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function getUserById(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/${id}`, config)
    console.debug(res)
    return res
  } catch (error) {
    console.debug(error)
    return error
  }
}

export async function updateUserById(userToken, id, professionalBasicInfo) {
  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.post(`${BASE_URL}users/${id}`, professionalBasicInfo, config)
    return res
  } catch (error) {
    return error
  }
}

export async function syncProfessional(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
    },
  }
  try {
    const res = await axios.post(`${BASE_URL}users/professional/${id}/sync-data`, config)
    console.debug(res)
    return res
  } catch (error) {
    return error
  }
}

export async function getFormTemplates(userToken, professionalId) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(
      `${BASE_URL_PATIENT}user/form-data/templates?professional_id=${professionalId}`,
      config,
    )
    console.debug('formTempRES', res)
    return res
  } catch (error) {
    return error
  }
}
