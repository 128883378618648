import { useEffect } from 'react'
import { isAuthRequired } from '../utils/constants'
import useAuth from './useAuth'
import toast from 'react-hot-toast'
import { useTheme } from '../context/theme/ThemeContext'

const useRequiredAuthCheck = () => {
  const { logout, user } = useAuth()
  const { colors } = useTheme()

  useEffect(() => {
    if (!user) return

    const versionNumber = process.env.REACT_APP_VERSION
    const authRequired = isAuthRequired[versionNumber]
    const key = `auth-check-${versionNumber}`

    if (!authRequired || localStorage.getItem(key)) return

    logout()
    localStorage.clear()
    localStorage.setItem(key, 'true')
    toast('Sie wurden abgemeldet. Ihre Sitzung ist abgelaufen oder ein Update wurde ausgeführt!', {
      style: {
        border: `1px solid ${colors.secondary}`,
      },
    })
  }, [])
}

export default useRequiredAuthCheck
