import {
  GET_ALL_PATIENT_SUCCESS,
  GET_ALL_PATIENT_FAIL,
  GET_PATIENT_ACCOUNT_DETAILS_SUCCESS,
  GET_PATIENT_ACCOUNT_DETAILS_FAIL,
  GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS,
  GET_PATIENT_APPOINTMENT_DETAILS_FAIL,
  UPDATE_PATIENT_ACCOUNT_INFORMATION_SUCCESS,
  UPDATE_PATIENT_ACCOUNT_INFORMATION_FAIL,
  GET_PATIENT_HEALTH_PROFILE_DETAILS_SUCCESS,
  GET_PATIENT_HEALTH_PROFILE_DETAILS_FAIL,
  GET_PATIENT_BY_ID_SUCCESS,
  GET_PATIENT_BY_ID_FAIL,
  GET_PATIENT_ADDRESS_DETAILS_SUCCESS,
  GET_PATIENT_ADDRESS_DETAILS_FAIL,
  UPDATE_PATIENT_BASIC_INFO_SUCCESS,
  UPDATE_PATIENT_BASIC_INFO_FAIL,
  UPDATE_PATIENT_ADDRESS_INFO_SUCCESS,
  UPDATE_PATIENT_ADDRESS_INFO_FAIL,
  UPDATE_PATIENT_HEALTH_INFO_SUCCESS,
  UPDATE_PATIENT_HEALTH_INFO_FAIL,
  SYNC_PATIENT_DATA_SUCCESS,
  SYNC_PATIENT_DATA_FAIL,
  CLEAR_OBJECTS,
  CLEAR_ERRORS,
  SET_TO_DEFAULT,
} from '../../store/actions'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_ALL_PATIENT_SUCCESS:
      return {
        ...state,
        patients: action.payload,
        successMessage: action.successMessage,
      }
    case GET_PATIENT_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        patientAccountDetails: action.payload,
      }
    case GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS:
      return {
        ...state,
        patientAppointmentDetails: action.payload,
      }
    case UPDATE_PATIENT_ACCOUNT_INFORMATION_SUCCESS:
    case UPDATE_PATIENT_BASIC_INFO_SUCCESS:
    case UPDATE_PATIENT_ADDRESS_INFO_SUCCESS:
    case UPDATE_PATIENT_HEALTH_INFO_SUCCESS:
      return {
        ...state,
      }
    case GET_PATIENT_HEALTH_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        patientHealthInsuranceDetails: action.payload,
      }
    case GET_PATIENT_BY_ID_SUCCESS:
      return {
        ...state,
        singlePatientRecord: action.payload,
        successMessage: action.successMessage,
      }
    case GET_PATIENT_ADDRESS_DETAILS_SUCCESS:
      return {
        ...state,
        patientAddressDetails: action.payload,
      }
    case CLEAR_OBJECTS:
      return {
        ...state,
        singlePatientRecord: null,
        patientAccountDetails: null,
        patientAppointmentDetails: null,
        patientHealthInsuranceDetails: null,
        patientAddressDetails: null,
      }
    case SET_TO_DEFAULT:
      return {
        ...state,
        // patients: [],
        singlePatientRecord: null,
        patientAccountDetails: null,
        patientAppointmentDetails: null,
        patientHealthInsuranceDetails: null,
        patientAddressDetails: null,
        successMessage: null,
        error: null,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        successMessage: null,
      }

    case GET_ALL_PATIENT_FAIL:
    case GET_PATIENT_ACCOUNT_DETAILS_FAIL:
    case GET_PATIENT_APPOINTMENT_DETAILS_FAIL:
    case UPDATE_PATIENT_ACCOUNT_INFORMATION_FAIL:
    case GET_PATIENT_HEALTH_PROFILE_DETAILS_FAIL:
    case GET_PATIENT_BY_ID_FAIL:
    case GET_PATIENT_ADDRESS_DETAILS_FAIL:
    case UPDATE_PATIENT_BASIC_INFO_FAIL:
    case UPDATE_PATIENT_ADDRESS_INFO_FAIL:
    case UPDATE_PATIENT_HEALTH_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
