import React, { useEffect, useRef } from 'react'
import { Animation } from './LogoClasses'
import { spinnerConfig } from './SpinnerConfig'
import './style.css'
import { useTheme } from '../../context/theme/ThemeContext'

const EternoSpinner = () => {
  const firstRun = useRef(true)
  const { colors } = useTheme()

  useEffect(() => {
    if (!firstRun.current) return
    firstRun.current = false
    const { amount, duration, delay, dotSize, dynamicDotSize } = spinnerConfig.settings
    const newAnimation = new Animation(
      amount,
      duration,
      delay,
      dotSize,
      dynamicDotSize,
      spinnerConfig.positions,
      colors.secondary,
    )
    newAnimation.start()
  }, [])

  return (
    <div style={{ transform: `scale(${spinnerConfig.settings.scale})` }}>
      <svg
        overflow="visible"
        className="svg"
        width="199"
        height="75"
        viewBox="0 0 199 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="path"
          d="M107.668 26.853C130.947 4.69723 145.584 2.66457 165.102 6.62825C175.063 8.6513 198.748 20.0437 193.157 48.8057C188.732 71.5713 158.697 70.4358 144.873 67.0995C136.029 64.9652 117.833 57.5407 93.5386 42.1996C74.2248 30.0037 56.1308 12.1658 32.5478 13.0311C10.3876 13.8442 5 32.8494 5 40.5734C5 52.871 12.5219 68.2174 33.1575 69.8436C54.911 71.5577 73.5129 58.664 76.8677 56.6314"
          strokeWidth="10"
          strokeLinecap="round"
        />
      </svg>
    </div>
  )
}

export default EternoSpinner
