import React from 'react'
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom'
import useAuth from './hooks/useAuth'
import './scss/style.scss'
import MagicLinkPage from './views/pages/magiclink/MagicLinkPage'
import useThemeOverride from './hooks/useThemeOverride'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Navigation = () => {
  const { user } = useAuth()
  useThemeOverride()

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => {
              if (user !== null) {
                return <DefaultLayout {...props} />
              } else {
                return <Login {...props} />
              }
            }}
          />
          <Route
            exact
            path="/magic-link"
            render={(props) => {
              if (user !== null) {
                return <DefaultLayout {...props} />
              } else {
                return <MagicLinkPage {...props} />
              }
            }}
          />
          <Route exact path="/404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" render={(props) => <Page500 {...props} />} />
          <Route
            path="/"
            render={(props) => {
              if (user !== null) {
                return <DefaultLayout {...props} />
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from: props.location },
                    }}
                  />
                )
              }
            }}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default Navigation
