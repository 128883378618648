import { useEffect, useState } from 'react'
import { useAdminConfigStore, useLocationStore, usePrismicStore } from '../zustandStore'
import useAuth from './useAuth'
import useSWRImmutable from 'swr/immutable'
import { getAllByTag, getAllByType, getByFilter, getSingle } from '../utils/fetchers'
import { client } from '../Prismic'
import { isCorrectWhiteLabelDomain, isEternoDomain, isLocalEnvironment } from '../utils/helpers'

const usePrismic = () => {
  const storedAppointmentTypes = usePrismicStore((state) => state.appointmentTypes)
  const [shouldFetch, setShouldFetch] = useState(false)

  const { data: adminScopesPage } = useSWRImmutable(
    shouldFetch ? ['config_admin_scopes', { lang: 'en-us' }] : null,
    getSingle,
  )
  const { data: adminGroupsPage } = useSWRImmutable(
    shouldFetch ? ['config_admin_groups', { lang: 'en-us' }] : null,
    getSingle,
  )
  const { data: todaysAppointmentsPage } = useSWRImmutable(
    shouldFetch ? ['todays_appointment_card', { lang: 'de-de' }] : null,
    getSingle,
  )
  const { data: professionalPages } = useSWRImmutable(
    shouldFetch ? ['professional_profile', { lang: 'de-de' }] : null,
    getAllByType,
  )
  const { data: instanceConfigs } = useSWRImmutable(
    shouldFetch ? ['config_dc_instance', { lang: 'de-de' }] : null,
    getAllByType,
  )
  const { data: customQuestions } = useSWRImmutable(
    shouldFetch ? ['custom_question', { lang: 'de-de' }] : null,
    getAllByType,
  )
  const { data: appointmentTypes } = useSWRImmutable(
    shouldFetch || !storedAppointmentTypes ? ['service_or_treatment', { lang: 'de-de' }] : null,
    getAllByType,
  )
  const { data: consentFormsTagDocuments } = useSWRImmutable(
    shouldFetch ? ['consent_forms_treatment', { lang: 'de-de' }] : null,
    getAllByTag,
  )
  const { data: customerConfigDocuments } = useSWRImmutable(
    [
      {
        'my.customer_config.environment':
          process.env.REACT_APP_ENVIRONMENT === 'local' ? 'dev' : process.env.REACT_APP_ENVIRONMENT,
      },
      {
        lang: 'de-de',
        pageSize: 200,
      },
    ],
    getByFilter,
  )
  const { data: dataAssets } = useSWRImmutable(
    shouldFetch ? ['data_asset_dashboard', { lang: 'de-de' }] : null,
    getSingle,
  )
  const { data: locationHubs } = useSWRImmutable(
    shouldFetch ? ['hub', { lang: 'de-de' }] : null,
    getAllByType,
  )

  const { data: secondaryLocationMappings } = useSWRImmutable(
    ['secondary_location_mapping', { lang: 'de-de' }],
    getAllByType,
  )
  const { data: secondaryLocations } = useSWRImmutable(
    ['secondary_location', { lang: 'de-de' }],
    getAllByType,
  )

  const { data: welcomeTreatmentContractPage } = useSWRImmutable(
    shouldFetch ? ['welcome_treatment_contract', { lang: 'de-de' }] : null,
    getSingle,
  )

  const { user } = useAuth()
  const userGroups = usePrismicStore((state) => state.userGroups)
  const locations = useLocationStore((state) => state.locations)
  const timestamp = usePrismicStore((state) => state.timestamp)
  const prismicRef = usePrismicStore((state) => state.ref)
  const currentCustomer = usePrismicStore((state) => state.currentCustomer)
  const storedInstanceConfigs = usePrismicStore((state) => state.instanceConfigs)
  const appVersion = usePrismicStore((state) => state.appVersion)

  const filterByEnvironmentTag = (prismicData) => {
    return prismicData.filter((entry) =>
      entry.tags.includes(
        process.env.REACT_APP_ENVIRONMENT === 'local' ? 'dev' : process.env.REACT_APP_ENVIRONMENT,
      ),
    )
  }

  useEffect(async () => {
    if (!user) return

    const now = Date.now()
    const isVersionMismatch = appVersion !== process.env.REACT_APP_VERSION
    const timeElapsed = !timestamp || (now - parseInt(timestamp, 10)) / 60000 > 30

    if (timeElapsed || isVersionMismatch) {
      const newStatePartial = {
        timestamp: now,
      }

      if (isVersionMismatch) {
        usePrismicStore.getState().resetStore()
        newStatePartial.appVersion = process.env.REACT_APP_VERSION
      }

      const { ref } = await client.getMasterRef()
      if (isVersionMismatch || ref !== prismicRef) {
        newStatePartial.ref = ref
        setShouldFetch(true)
      }

      usePrismicStore.setState(newStatePartial)
    }
  }, [user])

  useEffect(() => {
    if (adminScopesPage?.data?.scopes) {
      usePrismicStore.setState({ userScopes: adminScopesPage?.data?.scopes })
    }
  }, [adminScopesPage])

  useEffect(() => {
    if (!locationHubs) return
    usePrismicStore.setState({
      locationHubs: filterByEnvironmentTag(locationHubs),
    })
  }, [locationHubs])

  useEffect(() => {
    if (todaysAppointmentsPage?.data) {
      usePrismicStore.setState({ todaysAppointmentsPrismicData: todaysAppointmentsPage.data })
    }
  }, [todaysAppointmentsPage])

  useEffect(() => {
    if (adminGroupsPage?.data?.groups) {
      const groups = adminGroupsPage?.data?.groups.reduce((obj, group) => {
        if (
          (process.env.REACT_APP_ENVIRONMENT === 'dev' &&
            group.group[0].text.startsWith('prod-')) ||
          (process.env.REACT_APP_ENVIRONMENT === 'prod' && group.group[0].text.startsWith('dev-'))
        ) {
          return obj
        }

        if (!obj[group.group[0].text]) obj[group.group[0].text] = []
        if (group.app_component_key[0]?.text) {
          obj[group.group[0].text].push(group.app_component_key[0].text)
        }
        return obj
      }, {})

      usePrismicStore.setState({ userGroups: groups })
    }
  }, [adminGroupsPage])

  useEffect(() => {
    if (!userGroups || !user || locations === undefined) return

    const renderedPages = {}
    const featureAccess = {}

    user.groups?.forEach((group) => {
      userGroups[group]?.forEach((key) => {
        if (locations.length === 0 && key === 'eterno-today-appointments') return
        if (!key.startsWith('feature') && !renderedPages[key]) renderedPages[key] = key
        if (key.startsWith('feature') && !featureAccess[key]) featureAccess[key] = key
      })
    })

    useAdminConfigStore.setState({ renderedPages: renderedPages, featureAccess: featureAccess })
  }, [adminGroupsPage, userGroups, locations, user])

  useEffect(() => {
    if (!professionalPages) return

    const filteredProfessionals = filterByEnvironmentTag(professionalPages)

    const professionals = filteredProfessionals.reduce((obj, professional) => {
      obj[professional.data.key[0].text] = professional.data
      return obj
    }, {})

    usePrismicStore.setState({ professionals })
  }, [professionalPages])

  useEffect(() => {
    if (!instanceConfigs) return

    const filteredConfigs = filterByEnvironmentTag(instanceConfigs)

    const configs = filteredConfigs.reduce((obj, config) => {
      obj[config.data.hub.id] = config.data
      return obj
    }, {})

    usePrismicStore.setState({ instanceConfigs: configs })
  }, [instanceConfigs])

  useEffect(() => {
    if (!customQuestions) return

    const { questionsObj, subQuestionsObj, allQuestionsObj } = customQuestions.reduce(
      (obj, question) => {
        obj.allQuestionsObj[question.id] = {
          ...question.data,
          prismic_id: question.id,
        }
        if (!question.data.question_key?.[0] || !question.data.title?.[0]) return obj

        const key = question.tags.includes('subquestion') ? 'subQuestionsObj' : 'questionsObj'

        obj[key][question.data.question_key[0].text] = question.data

        return obj
      },
      { questionsObj: {}, subQuestionsObj: {}, allQuestionsObj: {} },
    )

    usePrismicStore.setState({
      questions: questionsObj,
      subQuestions: subQuestionsObj,
      allQuestions: allQuestionsObj,
    })
  }, [customQuestions])

  useEffect(() => {
    if (!appointmentTypes) return

    const filteredAppointmentTypes = filterByEnvironmentTag(appointmentTypes)

    const appointmentTypesObj = filteredAppointmentTypes.reduce((obj, appointmentType) => {
      if (!appointmentType.data.key?.[0]?.text) return obj

      obj[appointmentType.data.key[0].text] = appointmentType.data
      return obj
    }, {})

    usePrismicStore.setState({ appointmentTypes: appointmentTypesObj })
  }, [appointmentTypes])

  useEffect(() => {
    if (!consentFormsTagDocuments) return
    usePrismicStore.setState({ consentFormDocuments: consentFormsTagDocuments })
  }, [consentFormsTagDocuments])

  useEffect(() => {
    if (!dataAssets) return
    usePrismicStore.setState({ labels: dataAssets })
  }, [dataAssets])

  useEffect(() => {
    if (!customerConfigDocuments || !user || !storedInstanceConfigs) return

    const customerConfigDocumentResults = customerConfigDocuments.results

    const filteredCustomerConfigDocumentsByDomain =
      !isEternoDomain() && !isLocalEnvironment()
        ? customerConfigDocumentResults.filter(
            (entry) =>
              !entry.data.is_eterno_customer && isCorrectWhiteLabelDomain(entry.data.domain),
          )
        : customerConfigDocumentResults.filter((entry) => entry.data.is_eterno_customer)

    const customerConfigsObj = filteredCustomerConfigDocumentsByDomain.reduce(
      (obj, customerConfig) => {
        if (!customerConfig.data?.key?.[0]?.text) return obj

        obj[customerConfig.data?.key?.[0]?.text] = customerConfig.data

        return obj
      },
      {},
    )

    const allCustomers = customerConfigDocumentResults.reduce((obj, customerConfig) => {
      if (!customerConfig.data?.key?.[0]?.text) return obj

      const instanceIds = customerConfig.data.locations.reduce((obj, location) => {
        if (!location?.location?.id || !storedInstanceConfigs[location.location.id]) return obj
        const instanceId = storedInstanceConfigs[location.location.id].instance_id?.[0]?.text

        if (!instanceId) return obj

        obj[instanceId] = true

        return obj
      }, {})

      obj[customerConfig.data?.key?.[0]?.text] = {
        ...customerConfig.data,
        instanceIds,
      }

      return obj
    }, {})

    usePrismicStore.setState({ allCustomers })
    useAdminConfigStore.setState({ customers: customerConfigsObj })

    const firstCustomer = user?.groups?.includes('admin')
      ? filteredCustomerConfigDocumentsByDomain[0].data
      : Object.values(customerConfigsObj).find(({ customer_id }) =>
          user?.customerIds.includes(customer_id[0].text),
        )

    usePrismicStore.setState({
      currentCustomer:
        user?.customerIds.includes(currentCustomer?.customer_id[0].text) &&
        customerConfigsObj[currentCustomer?.key[0].text]
          ? currentCustomer
          : firstCustomer,
    })
  }, [customerConfigDocuments, user, storedInstanceConfigs])

  useEffect(() => {
    if (!secondaryLocationMappings || !secondaryLocations) return
    const filteredMappings = filterByEnvironmentTag(secondaryLocationMappings)
    const filteredSecondary = filterByEnvironmentTag(secondaryLocations)

    const secondaryLocationsFilteredReduced = filteredSecondary.reduce((obj, loc) => {
      obj[loc.id] = loc.data
      return obj
    }, {})

    const secondaryLocationMappingsFilteredReduced = filteredMappings.reduce((obj, mapping) => {
      const map = mapping.data.secondary_locations.map((val) => {
        return secondaryLocationsFilteredReduced[val.secondary_location.id]
      })
      obj[mapping.id] = map
      return obj
    }, {})
    useLocationStore.setState({ secondaryLocations: secondaryLocationMappingsFilteredReduced })
  }, [secondaryLocationMappings, secondaryLocations])

  useEffect(() => {
    if (!welcomeTreatmentContractPage) return

    usePrismicStore.setState({ welcomeTreatmentContract: welcomeTreatmentContractPage.data })
  }, [welcomeTreatmentContractPage])
}

export default usePrismic
