import axios from 'axios'
import { getCustomerId } from '../../utils/helpers'

const BASE_URL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_DC_API_PATH}/`

export async function getPatients(userToken) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function getUserMappings(userToken) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/mappings`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function getPatientById(userToken, id, instanceId) {
  console.debug('id in getPatient => ', id)
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
    params: {
      instance_id: instanceId,
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/${id}`, config)
    console.debug(res)
    return res
  } catch (error) {
    console.debug(error)
    return error
  }
}

export async function getUserMappingById(userToken, id) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/${id}/mappings`, config)
    console.debug(res)
    return res
  } catch (error) {
    console.debug(error)

    return error
  }
}

export async function postUserMappingById(userToken, id, mappingDataInfo) {
  console.debug('in postUserMappingById')
  console.debug('id => ' + id)
  const config = {
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      customer_id: getCustomerId(),
    },
  }
  try {
    console.debug(`${BASE_URL}users/${id}/mappings`)
    const res = await axios.post(`${BASE_URL}users/${id}/mappings`, mappingDataInfo, config)
    console.debug(res)
    return res
  } catch (error) {
    console.debug(error)
    return error
  }
}

export async function getDataAssetsData(userToken, instanceId) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}analytics?instance_id=${instanceId}`, config)
    console.debug(res)
    if (res?.data?.charts) return [true, res?.data?.charts]
    else return [false, null]
  } catch (error) {
    console.debug(error)

    return [false, null]
  }
}
