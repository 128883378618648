import React, { createContext, useContext, useMemo } from 'react'
import { useAdminConfigStore, usePrismicStore } from '../../zustandStore'
import logo from 'src/assets/brand/logo.svg'
import logoWithoutText from 'src/assets/brand/logoWithoutText.svg'

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const currentCustomer = usePrismicStore((state) => state.currentCustomer)
  const customers = useAdminConfigStore((state) => state.customers)

  const selectedCustomerTheme = useMemo(() => {
    if (currentCustomer) return currentCustomer

    if (window.location.hostname.includes('gesund.cloud') || !customers)
      return {
        primary_color: '#FFC877',
        secondary_color: '#4C726D',
        cta_font_color_dark: 'black',
        cta_font_color_light: 'white',
        customer_icon_small: { url: logoWithoutText },
        customer_icon_large: { url: logo },
      }

    return Object.values(customers)[0]
  }, [currentCustomer, customers])

  return (
    <ThemeContext.Provider
      value={{
        theme: {
          colors: {
            primary: selectedCustomerTheme.primary_color,
            secondary: selectedCustomerTheme.secondary_color,
            ctaFontColorDark: selectedCustomerTheme.cta_font_color_dark,
            ctaFontColorLight: selectedCustomerTheme.cta_font_color_light,
          },
          vars: {
            '--cui-sidebar-bg': selectedCustomerTheme.secondary_color,
            '--cui-sidebar-brand-color': selectedCustomerTheme.secondary_color,
            '--cui-sidebar-brand-bg': 'none',
            '--cui-primary': selectedCustomerTheme.secondary_color,
            '--cui-secondary': selectedCustomerTheme.primary_color,
            '--cui-link-color': selectedCustomerTheme.secondary_color,
            '--cui-link-hover-color': selectedCustomerTheme.secondary_color,
            '--cui-dropdown-link-active-bg': selectedCustomerTheme.secondary_color,
          },
          logos: {
            small: selectedCustomerTheme.customer_icon_small.url,
            large: selectedCustomerTheme.customer_icon_large.url,
          },
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const { theme } = useContext(ThemeContext)

  return theme
}
