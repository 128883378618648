import * as prismic from '@prismicio/client'
import { client } from '../Prismic'

export const getSingle = async ([type, params]) => await client.getSingle(type, params)

export const getAllByType = async ([type, params]) => await client.getAllByType(type, params)

export const getAllByTag = async ([tag, params]) => await client.getAllByTag(tag, params)

// Filters usage example: https://prismic.io/docs/technical-reference/prismicio-client#query-filters
export const getByFilter = async ([filters, params]) =>
  await client.get({
    filters: Object.keys(filters).map((item) => prismic.filter.at(item, filters[item])),
    ...params,
  })
