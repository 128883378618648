import React, { useReducer } from 'react'
import { uuid } from 'uuidv4'
import AlertContext from './AlertContext'
import AlertReducer from './AlertReducer'

import { REMOVE_ALERT, SET_ALERT } from '../../store/actions'

const AlertState = (props) => {
  const initialState = []

  const [state, dispatch] = useReducer(AlertReducer, initialState)

  const setAlert = (msg, type, timeout = 2000) => {
    const id = uuid()
    dispatch({
      type: SET_ALERT,
      payload: { msg, type, id },
    })

    setTimeout(
      () =>
        dispatch({
          type: REMOVE_ALERT,
          payload: id,
        }),
      timeout,
    )
  }
  return (
    <AlertContext.Provider
      value={{
        alerts: state,
        setAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  )
}

export default AlertState
