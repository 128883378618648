import React, { useReducer, useContext } from 'react'
import {
  getUsers,
  getUserById,
  getUserMappingById,
  postUserMappingById,
  getUserMappings,
} from '../../api/dcprofessional/DcProfessionalCalls'
import DcProfessionalReducer from './DcProfessionalReducer'
import DcProfessionalContext from './DcProfessionalContext'
import {
  GET_ALL_DC_PROFESSIONALS_SUCCESS,
  GET_ALL_DC_PROFESSIONALS_FAIL,
  GET_DC_PROFESSIONAL_BY_ID_SUCCESS,
  GET_DC_PROFESSIONAL_BY_ID_FAIL,
  GET_DC_PROFESSIONAL_MAPPING_BY_ID_SUCCESS,
  GET_DC_PROFESSIONAL_MAPPING_BY_ID_FAIL,
  POST_PROFESSIONAL_DATA_SUCCESS,
  POST_PROFESSIONAL_DATA_FAIL,
  GET_DC_PROFESSIONAL_MAPPINGS_SUCCESS,
  GET_DC_PROFESSIONAL_MAPPINGS_FAIL,
  CLEAR_OBJECTS,
  CLEAR_ERRORS,
  SET_TO_DEFAULT,
} from '../../store/actions'
const DcProfessionalState = (props) => {
  const initialState = {
    dcProfessionals: [],
    dcProfessionalsMapping: [],
    dcProfessionalsIds: null,
    singleDcProfessionalRecord: null,
    error: null,
    successMessage: null,
  }
  const [state, dispatch] = useReducer(DcProfessionalReducer, initialState)
  // getDcProfessionals
  const getDcProfessionals = async (userToken) => {
    try {
      var response = await getUsers(userToken)
      dispatch({
        type: GET_ALL_DC_PROFESSIONALS_SUCCESS,
        payload: response.data.data,
        successMessage: response.data.data.length !== 0 ? 'Record Found!' : 'Record Not Found!!',
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_DC_PROFESSIONALS_FAIL,
        payload: response.message,
      })
    }
  }

  // getDcProfessionalsMapping
  const getDcProfessionalsMapping = async (userToken) => {
    try {
      var response = await getUserMappings(userToken)
      dispatch({
        type: GET_DC_PROFESSIONAL_MAPPINGS_SUCCESS,
        payload: response.data.data,
        successMessage: 'Record Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_DC_PROFESSIONAL_MAPPINGS_FAIL,
        payload: response.message,
      })
    }
  }

  // getPatientsCall
  const getDcProfessionalMappingByIds = async (userToken, id) => {
    try {
      var response = await getUserMappingById(userToken, id)
      dispatch({
        type: GET_DC_PROFESSIONAL_MAPPING_BY_ID_SUCCESS,
        payload: response.data,
        successMessage: 'Record Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_DC_PROFESSIONAL_MAPPING_BY_ID_FAIL,
        payload: response.message,
      })
    }
  }

  // getDcProfessionalById
  const getDcProfessionalById = async (userToken, id) => {
    try {
      var response = await getUserById(userToken, id)
      dispatch({
        type: GET_DC_PROFESSIONAL_BY_ID_SUCCESS,
        payload: response.data,
        successMessage: 'Record Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_DC_PROFESSIONAL_BY_ID_FAIL,
        payload: response.message,
      })
    }
  }

  // sendProfessionalMappingData
  const sendProfessionalMappingData = async (userToken, id, mappingDataInfo) => {
    try {
      var response = await postUserMappingById(userToken, id, mappingDataInfo)

      dispatch({
        type: POST_PROFESSIONAL_DATA_SUCCESS,
        payload: response.data.data,
      })
    } catch (error) {
      dispatch({
        type: POST_PROFESSIONAL_DATA_FAIL,
        payload: response.message,
      })
    }
  }

  const clearRecords = () => {
    dispatch({ type: CLEAR_OBJECTS })
  }

  const clearError = () => {
    dispatch({ type: CLEAR_ERRORS })
  }
  const setToDefault = () => {
    dispatch({ type: SET_TO_DEFAULT })
  }

  return (
    <DcProfessionalContext.Provider
      value={{
        dcProfessionals: state.dcProfessionals,
        dcProfessionalsMapping: state.dcProfessionalsMapping,
        singleDcProfessionalRecord: state.singleDcProfessionalRecord,
        dcProfessionalsIds: state.dcProfessionalsIds,
        successMessage: state.successMessage,
        error: state.error,
        getDcProfessionals,
        getDcProfessionalById,
        getDcProfessionalsMapping,
        getDcProfessionalMappingByIds,
        sendProfessionalMappingData,
        clearRecords,
        clearError,
        setToDefault,
      }}
    >
      {props.children}
    </DcProfessionalContext.Provider>
  )
}
export default DcProfessionalState
