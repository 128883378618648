import axios from 'axios'
import https from 'https'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { usePrismicStore, useProfessionalsStore } from '../zustandStore'
import { getCachedUserData } from '../api/pracuseravailability/pracUserAvailability'

export const FeatureKey = {
  CONSENT_FORMS: 'feature-consent-forms',
  ANAMNESIS_BUILDER: 'feature-anamnesis-builder',
  CUSTOM_ANAMNESIS: 'feature-custom-anamnesis',
}

export const InsuranceType = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  SELF_PAYER: 'SELF_PAYER',
}

export function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const swrFetcher = ([url, config]) => axios.get(url, config).then((res) => res.data)

export const getHealthProfileData = async (userToken, id) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/user/health-profiles/${id}`,
      config,
    )
    console.debug(res)
    return res
  } catch (error) {
    console.debug(error)
    return error
  }
}

export const getExternalPatients = async (userToken) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/user/form-data/external-form-data`,
      config,
    )
    console.debug(response)
    return [response?.data?.data, 200]
  } catch (error) {
    console.debug(error)
    return [null, error.response.status]
  }
}

export const getExternalPatientDetails = async (userToken, userId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/user/form-data/${userId}`,
      config,
    )
    console.debug(response)
    return [response?.data, 200]
  } catch (error) {
    console.debug(error)
    return [null, error.response.status]
  }
}

export const getAdminUsers = async (userToken) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADMIN_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/users`,
      config,
    )
    console.debug(response)
    return response.data.data
  } catch (error) {
    console.debug(error)
    return null
  }
}

export const syncPatientWithId = async (userToken, instanceId, dcPatientId) => {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
    params: {
      instance_id: instanceId,
      dc_user_id: dcPatientId,
    },
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/doc-cirrus/user/sync`,
      {},
      config,
    )
    console.debug(response)
    return response.status === 200
  } catch (error) {
    console.debug(error)
    return error.status
  }
}

export const getSharedDocumentsByUserId = async (userToken, userId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/user/documents/shared?user_id=${userId}`,
      config,
    )
    console.debug(response)
    return response.data?.data
  } catch (error) {
    console.debug(error)
    return null
  }
}

export const getExtAuthToken = async (userToken, instanceId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/call-system/ext/auth?instance_id=${instanceId}`,
      config,
    )
    return response.data?.token
  } catch (error) {
    console.debug(error)
    return null
  }
}

export const getUnknownAppointments = async (userToken, locationKey) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/call-system/users/unknown?location_key=${locationKey}`,
      config,
    )
    console.debug(response)
    return response.data
  } catch (error) {
    console.debug(error)
    return null
  }
}

export const printPcsTicket = async (authToken, endpoint, certificateText) => {
  try {
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
        cert: certificateText,
      }),
      headers: {
        token: authToken,
      },
    })
    const response = await axiosInstance.get(`${process.env.REACT_APP_PROTOCOL}://${endpoint}/`)
    console.debug(response)
    return response.data?.id
  } catch (error) {
    console.debug(error)
    return null
  }
}

export const updatePcs = async (authToken, endpoint, certificateText, stack, ticket = null) => {
  try {
    const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
        cert: certificateText,
      }),
      headers: {
        token: authToken,
      },
    })
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_PROTOCOL}://${endpoint}/?stack=${stack}${
        ticket ? `&ticket=${ticket}` : ''
      }`,
    )
    console.debug(response)
    if (ticket) return response.status
    else return response.data?.id
  } catch (error) {
    console.debug(error)
    return null
  }
}

export const saveScopes = async (token, scopes, user) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      customer_id: getCustomerId(),
    },
  }

  const data = {
    id: user.id,
    username: user.username,
    email: user.email,
    scopes,
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADMIN_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/users/scopes`,
      data,
      config,
    )

    console.debug(response)
    return true
  } catch (error) {
    console.debug(error)
    return false
  }
}

export const createUser = async (token, user_email_list) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      customer_id: getCustomerId(),
    },
  }

  const promises = user_email_list.map((user_email) => {
    const data = {
      email: user_email,
    }

    return axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADMIN_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/users/create-user`,
        data,
        config,
      )
      .then((res) => {
        console.debug(res)
        return {
          user_email: user_email,
          success: true,
        }
      })
      .catch((e) => {
        console.debug(e)
        return {
          user_email: user_email,
          success: false,
          message: e.response?.data?.error ?? e.message,
        }
      })
  })

  return await Promise.all(promises)
}

export const saveCustomers = async (token, customer_ids, instance_ids, user) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      customer_id: getCustomerId(),
    },
  }

  const data = {
    id: user.id,
    username: user.username,
    email: user.email,
    customer_ids,
    instance_ids,
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADMIN_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/users/customers`,
      data,
      config,
    )

    console.debug(response)
    return true
  } catch (error) {
    console.debug(error)
    return false
  }
}

export const addGroup = async (token, group, user) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      customer_id: getCustomerId(),
    },
  }

  const data = {
    id: user.id,
    username: user.username,
    email: user.email,
    group,
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADMIN_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/users/add-group`,
      data,
      config,
    )

    console.debug(response)
    return true
  } catch (error) {
    console.debug(error)
    return false
  }
}

export const removeGroup = async (token, group, user) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      customer_id: getCustomerId(),
    },
  }

  const data = {
    id: user.id,
    username: user.username,
    email: user.email,
    group,
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADMIN_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/users/remove-group`,
      data,
      config,
    )

    console.debug(response)
    return true
  } catch (error) {
    console.debug(error)
    return false
  }
}

export const getAllGroups = async (token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      customer_id: getCustomerId(),
    },
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADMIN_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/users/groups`,
      config,
    )

    console.debug(response)
    return response.data
  } catch (error) {
    console.debug(error)
    return false
  }
}

export const isLocalEnvironment = () => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
}

export const isEternoDomain = () =>
  window.location.hostname.includes(process.env.REACT_APP_ETERNO_DOMAIN)

export const isCorrectWhiteLabelDomain = (domain) => window.location.hostname.includes(domain)

export const getDomain = (customer) => {
  if (isEternoDomain()) return 'eterno-health.io'

  if (isCorrectWhiteLabelDomain(customer.domain))
    return `${customer.domain}/customer/${customer.key[0].text}`

  return `gesund.cloud/customer/${customer.key[0].text}`
}

export const hasUndefinedProperty = (arr) => {
  for (const obj of arr) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === undefined) {
        return true
      }
    }
  }
  return false
}

export const loadCachedUserData = async (userToken) => {
  try {
    useProfessionalsStore.setState({ finalRecords: [] })
    const response = await getCachedUserData(userToken)

    if (!response?.data?.data?.linked_users) return false

    useProfessionalsStore.setState({ cachedUserData: response?.data?.data?.linked_users })
    return true
  } catch (e) {
    console.debug(e)
    return false
  }
}

export const getCustomerId = () => {
  const customer = usePrismicStore.getState().currentCustomer
  return customer?.customer_id?.[0]?.text
}
