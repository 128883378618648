import axios from 'axios'
import { getCustomerId } from '../../utils/helpers'

const BASE_URL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PRACTITIONER_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/`
const BASE_URL_PATIENT = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_PATIENT_API_DOMAIN}/${process.env.REACT_APP_SECURE_ADMIN_API_PATH}/`

export async function getCachedUserData(userToken) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
    params: {
      data_type: 'linked_users',
    },
  }
  try {
    const res = await axios.get(`${BASE_URL}users/availability/cached-data`, config)
    return res
  } catch (error) {
    return error
  }
}

export async function updateForm(userToken, formData) {
  const config = {
    headers: {
      Authorization: userToken,
      customer_id: getCustomerId(),
    },
  }
  try {
    const res = await axios.post(`${BASE_URL_PATIENT}user/form-data/templates`, formData, config)
    return res
  } catch (error) {
    return error
  }
}
