import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { createContext, useEffect, useReducer } from 'react'

// third-party
import { Auth } from 'aws-amplify'

// action - state management
import { LOGIN, LOGOUT, REFRESH_TOKEN } from '../store/actions'
import accountReducer from '../store/accountReducer'

// project imports

// firebase initialize

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  userToken: '',
}

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const AWSContext = createContext(null)

export const AWSProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState)
  const [refreshInterval, setRefreshInterval] = useState(null)

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.debug('Not signed in'))
  }

  const getRefreshToken = async () => {
    const res = await Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.debug('Not signed in'))

    if (res) {
      dispatch({
        type: REFRESH_TOKEN,
        payload: res.signInUserSession.idToken.jwtToken,
      })
    }
  }

  const refreshSession = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const { refreshToken } = cognitoUser.getSignInUserSession()
    cognitoUser.refreshSession(refreshToken, (err, session) => {
      if (err) {
        console.debug(err)
        return
      }
      dispatch({
        type: REFRESH_TOKEN,
        payload: session.idToken.jwtToken,
      })
    })
  }

  useEffect(() => {
    login()
  }, [dispatch])

  useEffect(() => {
    if (!state.isInitialized || !state.isLoggedIn || refreshInterval) return
    setRefreshInterval(setInterval(refreshSession, 3300000)) // every 55 minutes

    return () => {
      if (refreshInterval) clearInterval(refreshInterval)
    }
  }, [state])

  const login = () => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        getRefreshToken()
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: {
              id: userData.username,
              email: userData.attributes.email,
              name: userData.displayName || '',
              userToken: userData.signInUserSession.idToken.jwtToken,
              groups: userData.signInUserSession.idToken.payload['cognito:groups'],
              customerIds: userData.attributes['custom:active_customer_ids']?.split(',') ?? [],
            },
          },
        })
      })
      .catch(() =>
        dispatch({
          type: LOGOUT,
        }),
      )
  }

  const logout = () => {
    Auth.signOut()
    dispatch({
      type: LOGOUT,
    })
  }

  const updateProfile = () => {}
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <div></div>
  }

  return (
    <AWSContext.Provider
      value={{
        ...state,
        userToken: state.userToken,
        logout,
        login,
        updateProfile,
        getRefreshToken,
      }}
    >
      {children}
    </AWSContext.Provider>
  )
}

AWSContext.propTypes = {
  children: PropTypes.node,
}

export default AWSContext
