// action - state management
import { LOGIN, LOGOUT, SET_USER_SCOPE, REFRESH_TOKEN } from './actions'

// ==============================|| ACCOUNT REDUCER ||============================== //

const accountReducer = (state, action) => {
  switch (action.type) {
    case LOGIN: {
      const { user } = action.payload
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
      }
    }
    case REFRESH_TOKEN: {
      console.debug('userToken = > ' + action.payload)
      return {
        ...state,
        userToken: action.payload,
      }
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default accountReducer
