import React, { useReducer } from 'react'
import {
  getTodayAppointments,
  updateUserByNumber,
  getAppointments,
} from '../../api/appointment/AppointmentCalls'
import AppointmentReducer from './AppointmentReducer'
import AppointmentContext from './AppointmentContext'
import {
  GET_ALL_TODAYS_APPOINTMENTS_SUCCESS,
  GET_ALL_TODAYS_APPOINTMENTS_FAIL,
  GET_ALL_APPOINTMENTS_SUCCESS,
  GET_ALL_APPOINTMENTS_FAIL,
  UPDATE_USER_BY_NUMBER_SUCCESS,
  UPDATE_USER_BY_NUMBER_FAIL,
  CLEAR_CHECK_IN_SUCCESS,
  CLEAR_OBJECTS,
  CLEAR_ERRORS,
  SET_TO_DEFAULT,
} from '../../store/actions'
import { useLocationStore, useTodaysAppointmentsStore } from '../../zustandStore'

const AppointmentState = (props) => {
  const initialState = {
    appointments: [],
    todaysAppointments: [],
    checkInDetails: null,
    selectedAppointments: [],
    code: null,
    error: null,
    successMessage: null,
  }
  const [state, dispatch] = useReducer(AppointmentReducer, initialState)

  //getAllTodaysAppointments
  const getAllTodaysAppointments = async (userToken, currentDate, selectedLocation) => {
    console.debug('in getAllTodaysAppointment')
    try {
      var response = await getTodayAppointments(userToken, currentDate, selectedLocation)
      console.debug('response == ', response)
      const inactiveAppointments = response.data.data.inactive
        .filter((entry) => entry.status !== 'CANCELLED')
        .map((entry) => {
          return {
            ...entry,
            inactive: true,
          }
        })
      const payload = [...response.data.data.active, ...inactiveAppointments]
      if (payload.length === 0) useLocationStore.setState({ loading: false })
      dispatch({
        type: GET_ALL_TODAYS_APPOINTMENTS_SUCCESS,
        payload: payload,
        successMessage:
          response.data.data.active.length !== 0 ? 'Record Found!!' : 'Record Not Found!',
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_TODAYS_APPOINTMENTS_FAIL,
        payload: response.message,
      })
    }
    useTodaysAppointmentsStore.setState({ isTodaysAppointmentsLoading: false })
  }

  //getAllsAppointments
  const getAllAppointments = async (userToken) => {
    console.debug('in getAllAppointments')
    try {
      var response = await getAppointments(userToken)
      dispatch({
        type: GET_ALL_APPOINTMENTS_SUCCESS,
        payload: response.data.data.active,
        successMessage:
          response.data.data.active.length !== 0 ? 'Record Found!!' : 'Record Not Found!!',
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_APPOINTMENTS_FAIL,
        payload: response.message,
      })
    }
  }

  //updateAppointmentByUser
  const updateAppointmentByUser = async (userToken, userInfo, id) => {
    try {
      const response = await updateUserByNumber(userToken, userInfo, id)
      console.debug('response', response)
      if (response.status === 200) {
        dispatch({
          type: UPDATE_USER_BY_NUMBER_SUCCESS,
          payload: response.data,
        })
      }
    } catch (error) {
      dispatch({
        type: UPDATE_USER_BY_NUMBER_FAIL,
        payload: error.response.data,
      })
    }
  }

  const clearRecords = () => {
    dispatch({ type: CLEAR_OBJECTS })
  }

  const clearCheckinDetails = () => {
    dispatch({ type: CLEAR_CHECK_IN_SUCCESS })
  }

  const clearError = () => {
    dispatch({ type: CLEAR_ERRORS })
  }

  const setToDefault = () => {
    dispatch({ type: SET_TO_DEFAULT })
  }

  return (
    <AppointmentContext.Provider
      value={{
        todaysAppointments: state.todaysAppointments,
        appointments: state.appointments,
        checkInDetails: state.checkInDetails,
        selectedAppointments: state.selectedAppointments,
        code: state.code,
        error: state.error,
        successMessage: state.successMessage,
        getAllTodaysAppointments,
        getAllAppointments,
        updateAppointmentByUser,
        clearCheckinDetails,
        clearError,
        setToDefault,
        clearRecords,
      }}
    >
      {props.children}
    </AppointmentContext.Provider>
  )
}
export default AppointmentState
