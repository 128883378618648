import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import isEmail from 'validator/lib/isEmail'
import useAuth from '../../../hooks/useAuth'
import EternoSpinner from '../../../components/EternoLogoSpinner/EternoSpinner'

const MagicLinkPage = () => {
  const { login } = useAuth()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const signIn = async () => {
      const searchParams = new URLSearchParams(location.search)
      const token = searchParams.get('token')
      const email = searchParams.get('email')

      if (!token || !email || !isEmail(email)) {
        history.replace('/login')
        return
      }

      try {
        const cognitoUser = await Auth.signIn(email)
        await Auth.sendCustomChallengeAnswer(cognitoUser, token)
        login()
      } catch (e) {
        console.debug(e)
        history.replace('/login')
      }
    }

    signIn()
  }, [])

  return (
    <div className="bg-light min-vh-100 d-flex flex-column align-items-center justify-content-center gap-5">
      <h2 className="m-0 p-0">Bitte warten</h2>
      <EternoSpinner />
    </div>
  )
}

export default MagicLinkPage
